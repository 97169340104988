import React from "react";
import { Chip as MuiChip } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";

const Chip = styled(MuiChip)`
  margin: 2px;
  max-width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: white;
    cursor: pointer !important;
  }
`;

const Chips = (props) => {
  const {
    data,
    valueField,
    displayField,
    handleChipClick,
    activeChips = [],
    tooltipField1 = "",
    tooltipField2 = "",
    mode,
  } = props;

  const { options: parameterOptions } = data?.parameters;
  const { value: selectedParameterGroups } = data?.parameterGroups;

  return parameterOptions.map((d) => {
    const isGroupSelected = selectedParameterGroups?.includes(d.group);

    return (
      <Tooltip
        key={d[valueField]}
        arrow
        placement="bottom"
        title={
          tooltipField1 &&
          d[tooltipField1] + (tooltipField2 ? ` in ${d[tooltipField2]}` : "")
        }
      >
        <Chip
          data-value={d[valueField]}
          label={d[displayField]}
          color={activeChips.includes(d[valueField]) ? "secondary" : "default"}
          clickable={false}
          onClick={handleChipClick}
          disabled={mode !== "map-explorer" && !isGroupSelected}
        />
      </Tooltip>
    );
  });
};

export default Chips;
