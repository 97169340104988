import { useQuery } from "react-query";
import axios from "axios";
import { useMemo } from "react";

export function useWaterQualitySummary({ locationId, parameterIds, flow }) {
  const { data, isError, isLoading, refetch } = useQuery(
    ["water-quality-summary"],
    async () => {
      try {
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/wq-summary`
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const selectedLocationData = useMemo(() => {
    return data?.data?.filter((item) => {
      return (
        item[`has_${flow}`] &&
        item.locationid === locationId &&
        parameterIds.includes(item.parameter_index)
      );
    });
  }, [data, flow, locationId, parameterIds]);

  return {
    isError,
    isLoading,
    data: data?.data || [],
    refetch,
    selectedLocationData,
  };
}
