import TextField from "@material-ui/core/TextField";

const SimpleNumber = ({ label, name, onChange, value }) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={"number"}
    />
  );
};

export default SimpleNumber;
