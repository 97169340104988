import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import MapIcon from "@material-ui/icons/Map";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components/macro";
import LayersControl from "../layersControl";
import BasemapsControl from "../basemapsControl";
import PapsControl from "../papsControl";
import { TrendingDown } from "react-feather";
import BuildIcon from "@material-ui/icons/Build";
import ParametersControl from "../parametersControl";

const Container = styled(Paper)`
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  left: 49px;
  position: absolute;
  top: 10px;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 315px;
  z-index: 3;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: 450px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainControl = ({
  activeBasemap,
  basemaps,
  layers,
  filterValues,
  handleFilterValues,
  onBasemapChange,
  onLayerChange,
  map,
  features,
  onOpacityChange,
  defaultExpanded = "layers",
  displayPAP = true,
  defaultExpandedLayers,
  onSelectAll,
  onSelectNone,
  mode,
}) => {
  const [expandedItem, setExpandedItem] = useState(defaultExpanded);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);
  };

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current && mode) {
      // Skip initial setup logic
      initialRender.current = false;
    } else {
      if (mode === "graph-mode") {
        setExpandedItem("parameters");
      } else if (mode === "map-explorer") {
        setExpandedItem("layers");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <Container>
      <Accordion
        expanded={expandedItem === "basemaps"}
        onChange={handleChange("basemaps")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <MapIcon />
            <Typography variant="subtitle1">Basemaps</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BasemapsControl
            items={basemaps}
            value={activeBasemap}
            onBasemapChange={onBasemapChange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "layers"}
        onChange={handleChange("layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <LayersIcon />
            <Typography variant="subtitle1">Layers</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <LayersControl
            items={layers}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
            defaultExpandedLayers={defaultExpandedLayers}
          />
        </AccordionDetails>
      </Accordion>
      {displayPAP && (
        <Accordion
          expanded={expandedItem === "pap"}
          onChange={handleChange("pap")}
        >
          <AccordionSummary
            aria-controls="pap-content"
            id="pap-header"
            expandIcon={<ExpandMore />}
          >
            <Box alignItems="center" display="flex" gridColumnGap={8}>
              <TrendingDown />
              <Typography variant="subtitle1">
                Pollution Abatement Projects
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <PapsControl map={map} features={features} />
          </AccordionDetails>
        </Accordion>
      )}
      {mode === "graph-mode" && (
        <Accordion
          expanded={expandedItem === "parameters"}
          onChange={handleChange("parameters")}
        >
          <AccordionSummary
            aria-controls="parameters-content"
            id="parameters-header"
            expandIcon={<ExpandMore />}
          >
            <Box alignItems="center" display="flex" gridColumnGap={8}>
              <BuildIcon />
              <Typography variant="subtitle1">Parameters</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <ParametersControl
              filterValues={filterValues}
              handleFilterValues={handleFilterValues}
              onSelectAll={onSelectAll}
              onSelectNone={onSelectNone}
              mode={mode}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Container>
  );
};

export default MainControl;
