import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";

import { Grid as MuiGrid } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { groupByValueArray, lineColors } from "../../../utils";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";

import axios from "axios";
import { MultiSelect, Select } from "@lrewater/lre-react";
import useFetchData from "../../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw);
  height: calc(100% - 92px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const Grid = styled(MuiGrid)(spacing);

const StreamLoggerIFrame = () => {
  const saveRef = useRef(null);

  const parameterOptions = [
    {
      name: "Flow",
      id: 2,
    },
    {
      name: "Stage",
      id: 1901,
    },
  ];

  const [locationsOptions] = useFetchData("list-locations-telem", [], false);

  const [selectedParameter, setSelectedParameter] = useState(
    parameterOptions[0].id
  );

  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    if (locationsOptions.length) {
      setSelectedLocations(locationsOptions.map((item) => item.locationid));
    }
  }, [locationsOptions]);

  const handleFilter = (name, event) => {
    if ("selectedParameter" === name) {
      setSelectedParameter(event.target.value);
    } else if ("selectedLocations" === name) {
      setSelectedLocations(event.target.value);
    }
  };

  //date filter defaults
  const defaultFilterValues = {
    previousDays: "",
    //oct 1 of last year (first of water year)
    startDate: new Date(new Date().getFullYear(), -3, 1),
    endDate: new Date(),
    checked: false,
  };

  const [filterValues] = useState(defaultFilterValues);

  const [graphData, setGraphData] = useState([]);
  const { isFetching, error, data } = useQuery(
    [
      `api/stream-logger-results-viewer-daily-stats-table/${selectedParameter}/${selectedLocations}`,
      selectedParameter,
      selectedLocations,
    ],
    async () => {
      if (selectedLocations?.length === 0) {
        setGraphData([]);
        setFilteredMutatedGraphData([]);
        return null;
      }
      if (selectedParameter && locationsOptions?.length > 0) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/stream-logger-results-viewer-daily-stats-table/${selectedParameter}/${selectedLocations}`
          );
          const groupedDataArray = groupByValueArray(data, "rdate");

          setGraphData(groupedDataArray);

          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const uniqueLocations = useMemo(() => {
    if (data?.length) {
      const uniqueItemNames = [
        ...new Set(
          data.map((item) => {
            return item.location_name;
          })
        ),
      ];
      return uniqueItemNames;
    }
  }, [data]);

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState([]);
  //filtered data for graph, it filters selected locations
  //it keeps every date so the graph can still be panned and zoomed
  //it also mutates the data to be consumed by chartsJS
  //filtered data for table. if filters selected locations
  //it also filters by date
  useEffect(() => {
    if (data && graphData.length) {
      //mutate data for chartJS to use
      const defaultStyle = {
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        yAxisID: "yL",
        borderWidth: 2,
        tension: 0.75,
      };
      const mutatedGraphData = {
        labels: graphData.map((item) => item[0].rdate),
        units: graphData[0][0]?.result_unit_name,
        parameter: graphData[0][0]?.parameter,
        datasets: [
          ...uniqueLocations.map((location, i) => {
            return {
              units: graphData[0][0]?.result_unit_name,
              label: location,
              borderColor: Object.entries(lineColors)[i],
              backgroundColor: Object.entries(lineColors)[i],
              data: graphData.map(
                (item) =>
                  item.filter((arr) => arr.location_name === location)[0]
                    ?.daily_average ?? null
              ),
              ...defaultStyle,
            };
          }),
        ],
      };
      setFilteredMutatedGraphData(mutatedGraphData);
    }
  }, [data, graphData]); //eslint-disable-line

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TimeseriesContainer>
            <Grid
              container
              pb={6}
              mt={2}
              style={{ justifyContent: "space-between" }}
            >
              <Grid
                item
                style={{
                  flexGrow: 1,
                  maxWidth: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Select
                  name="parameter"
                  label="Parameter"
                  variant="outlined"
                  outlineColor="primary"
                  labelColor="primary"
                  valueField="id"
                  displayField="name"
                  data={parameterOptions}
                  value={selectedParameter}
                  onChange={(e) => handleFilter("selectedParameter", e)}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                style={{
                  flexGrow: 1,
                  maxWidth: "calc(100% - 150px - 70px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                <MultiSelect
                  fullWidth
                  data={locationsOptions}
                  valueField="locationid"
                  displayField="location_name"
                  onChange={(e) => {
                    if (e.target.value.includes("all/none")) {
                      return null;
                    } else {
                      handleFilter("selectedLocations", e);
                    }
                  }}
                  value={selectedLocations}
                  name="locations"
                  label="Locations"
                  variant="outlined"
                  fillColor="primary"
                  outlineColor="primary"
                  labelColor="primary"
                  margin="normal"
                  width="264px"
                />
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "6px",
                }}
              />
              <Grid
                item
                style={{
                  width: "53px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SaveGraphButton
                  ref={saveRef}
                  title="Stream Logger Data Viewer"
                />
              </Grid>
            </Grid>
            <TableWrapper>
              <TimeseriesLineChart
                data={filteredMutatedGraphData}
                error={error}
                isLoading={isFetching}
                filterValues={filterValues}
                yLLabel={`${filteredMutatedGraphData.parameter} (${filteredMutatedGraphData.units})`}
                ref={saveRef}
                tooltipFormat="MM-DD-YYYY"
                xLabelFormat="MM-DD-YYYY"
                // maxL={
                //   selectedParameter === 2
                //     ? 130
                //     : selectedParameter === 1901
                //     ? 5
                //     : null
                // }
              />
            </TableWrapper>
          </TimeseriesContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default StreamLoggerIFrame;
