import { useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { groupByValue } from "../../../../utils";

export function useWaterQualityTimeSeries({ locationId, parameterIds }) {
  const {
    data: dailyData,
    isError: isDailyDataError,
    // isLoading: isDailyDataLoading,
  } = useQuery(
    ["water-quality-daily-time-series", locationId, parameterIds],
    async () => {
      try {
        if (!locationId || !parameterIds?.length) return;
        const parameterIdsString = parameterIds.join(",");
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/wq-time-series/daily/${locationId}/${parameterIdsString}`
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: annualData,
    isError: isAnnualDataError,
    // isLoading: isAnnualDataLoading,
  } = useQuery(
    ["water-quality-annual-time-series", locationId, parameterIds],
    async () => {
      try {
        if (!locationId || !parameterIds?.length) return;
        const parameterIdsString = parameterIds.join(",");
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/wq-time-series/annual/${locationId}/${parameterIdsString}`
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading, isError, data } = useMemo(() => {
    const hasError = isDailyDataError || isAnnualDataError;
    const isLoading = !dailyData && !annualData;

    const hasDailyData = dailyData?.data?.length;
    const hasAnnualData = annualData?.data?.length;

    let lineData = {};
    let barData = {};

    if (hasDailyData) {
      lineData = groupByValue(dailyData?.data, "parameter_index");
    }

    if (hasAnnualData) {
      barData = groupByValue(annualData?.data, "parameter_index");
    }

    return {
      isError: hasError,
      isLoading,
      data: {
        line: lineData,
        bar: barData,
      },
    };
  }, [dailyData, annualData, isDailyDataError, isAnnualDataError]);

  return { isError, isLoading, data };
}
