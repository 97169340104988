import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import SearchIcon from "@material-ui/icons/Search";
import useDebounce from "../../../../hooks/useDebounce";
import { Alert } from "@material-ui/lab";

const PapsControl = ({ map, features }) => {
  const [value, setValue] = useState("");
  const [searchableFeatures, setSearchableFeatures] = useState([]);
  const debouncedSearchValue = useDebounce(value, 200);

  const normalizeString = (string) => string.toString().trim().toLowerCase();

  useEffect(() => {
    setSearchableFeatures(
      features.filter(
        (item) =>
          normalizeString(item.properties.Name).includes(
            normalizeString(debouncedSearchValue)
          ) ||
          normalizeString(item.properties.ID).includes(
            normalizeString(debouncedSearchValue)
          )
      )
    );
  }, [debouncedSearchValue]); //eslint-disable-line

  // Create a popup, but don't add it to the map yet.
  const popup = new mapboxgl.Popup({
    closeButton: false,
  });

  map.on("closeAllPopups", () => {
    popup.remove();
  });

  useEffect(() => {
    if (features) {
      setValue("");
      setSearchableFeatures(features);
    }
  }, [features]);

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  const handleMouseOver = (event, item) => {
    map.fire("closeAllPopups");
    popup
      .setLngLat([
        item?.geometry.type === "Point"
          ? item?.geometry?.coordinates[0]
          : item?.geometry.type === "LineString"
          ? item?.geometry?.coordinates[0][0]
          : item?.geometry?.coordinates[0][0][0],
        item?.geometry.type === "Point"
          ? item?.geometry?.coordinates[1]
          : item?.geometry.type === "LineString"
          ? item?.geometry?.coordinates[0][1]
          : item?.geometry?.coordinates[0][0][1],
      ])
      .setText(item.properties.Name)
      .addTo(map);
  };

  const handleSelect = (event, item) => {
    map?.flyTo({
      center: [
        item?.geometry.type === "Point"
          ? item?.geometry?.coordinates[0]
          : item?.geometry.type === "LineString"
          ? item?.geometry?.coordinates[0][0]
          : item?.geometry?.coordinates[0][0][0],
        item?.geometry.type === "Point"
          ? item?.geometry?.coordinates[1]
          : item?.geometry.type === "LineString"
          ? item?.geometry?.coordinates[0][1]
          : item?.geometry?.coordinates[0][0][1],
      ],
      zoom: 14,
    });
  };

  return (
    <Box style={{ width: "100%" }} display="flex" flexDirection="column">
      <List dense>
        {features?.length > 0 && (
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="Filter results by name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            placeholder="Name"
            style={{ width: "100%", minWidth: "220px" }}
            type="search"
            value={value}
            variant="outlined"
            size="small"
          />
        )}
        {features?.length === 0 ? (
          <Alert severity="info" style={{ alignItems: "center" }}>
            <Typography variant="body1">
              Select PAP layers and <strong>pan/zoom</strong> the map to
              populate PAP results
            </Typography>
          </Alert>
        ) : (
          <>
            {searchableFeatures
              .sort(function (a, b) {
                const textA = a.properties?.Name.toUpperCase();
                const textB = b.properties?.Name.toUpperCase();

                return textA.localeCompare(textB);
              })
              ?.map((item) => {
                return (
                  <Box key={item?.properties?.ID} id="layerCheck">
                    <ListItem
                      button
                      onClick={(event) => handleSelect(event, item)}
                      onMouseOver={(event) => handleMouseOver(event, item)}
                    >
                      <ListItemText
                        primary={`${item?.properties?.ID} | ${item?.properties?.Name}`}
                      />
                    </ListItem>
                    {/*<LayerLegend open={open} item={item} />*/}
                  </Box>
                );
              })}
          </>
        )}
      </List>
    </Box>
  );
};

export default PapsControl;
