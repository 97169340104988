import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Chip,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  isWidthUp,
  TextField,
  Typography as MuiTypography,
  withWidth,
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useApp } from "../../../AppProvider";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import QueryAndDownloadMap from "../../../components/map/QueryAndDownloadMap";
import { LocationCity } from "@material-ui/icons";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const RecordsAvailableChip = styled(Chip)`
  ${spacing};
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
`;

const MapContainer = styled.div`
  height: 694px;
  width: 100%;
`;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const QueryAndDownload = ({ width }) => {
  const { doToast, currentUser } = useApp();

  const initFilterValues = {
    flowConditions: [],
    parameterGroups: [],
    parameters: [],
    locationTypes: [],
    locations: [],
    startDate: null,
    endDate: null,
    uuid: null,
    uuidOrDefault: null,
    recordCount: "",
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const { data: userSelections } = useQuery(
    [`download-user-filters/${currentUser?.sub}`, currentUser],
    async () => {
      if (currentUser?.sub) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters/${currentUser?.sub}`
          );
          return data ?? {};
        } catch (err) {
          console.error(err);
        }
      }
      return {};
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [parameterGroups] = useFetchData("dropdown-parameter-group", [], false);
  const [locationTypes] = useFetchData("dropdown-location-type", [], false);
  const [flowConditions] = useFetchData("dropdown-flow-conditions", [], false);

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (
      parameterGroups.length &&
      locationTypes.length &&
      flowConditions.length
    ) {
      onFilterSelectAll("parameterGroups", parameterGroups, "param_group");
      onFilterSelectAll("locationTypes", locationTypes, "location_type");
      onFilterSelectAll("flowConditions", flowConditions, "flow_conditionsid");
      setHasFiltersLoaded(true);
    }
  }, [parameterGroups, locationTypes, flowConditions]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    ["dropdown-parameters", filterValues.parameterGroups, hasFiltersLoaded],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/dropdown-parameters`,
            {
              parameterGroups: filterValues.parameterGroups,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const cleanFilter = (filter, filterOptions, filterField) => {
    return filterOptions.filter((x) => {
      if (filter.find((item) => item[filterField] === x[filterField])) {
        return x;
      } else return null;
    });
  };

  useEffect(() => {
    if (parameters?.length && !hasParametersLoaded && userSelections) {
      setHasParametersLoaded(true);

      if (
        Object.entries(userSelections).length &&
        userSelections.user_uuid !== "public"
      ) {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            parameters: userSelections.parameters,
            startDate: convertUTCDateToLocalDate(
              new Date(userSelections.start_date)
            ),
            endDate: convertUTCDateToLocalDate(
              new Date(userSelections.end_date)
            ),
          };
        });
      } else {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            parameters: parameters,
          };
        });
      }
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      handleFilterValues(
        "parameters",
        cleanFilter(filterValues.parameters, parameters, "parameterid")
      );
    }
  }, [parameters]); //eslint-disable-line

  const [hasLocationsLoaded, setHasLocationsLoaded] = useState(false);
  const { data: locations } = useQuery(
    ["dropdown-locations-assoc", filterValues.locationTypes, hasFiltersLoaded],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/dropdown-locations-assoc`,
            {
              locationTypes: filterValues.locationTypes,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (locations?.length && !hasLocationsLoaded && userSelections) {
      setHasLocationsLoaded(true);

      if (Object.entries(userSelections).length) {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            locations: locations.filter((location) =>
              userSelections.locations.includes(location.locationid)
            ),
          };
        });
      } else {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            locations: locations,
          };
        });
      }
    }
  }, [parameters, locations]); //eslint-disable-line

  useEffect(() => {
    if (hasLocationsLoaded) {
      handleFilterValues(
        "locations",
        cleanFilter(filterValues.locations, locations, "locationid")
      );
    }
  }, [locations]); //eslint-disable-line

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  const getFilterNameByIndex = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterIndex] === name);
    return filter[filterField];
  };

  const handleFilterValues = (name, value) => {
    // if (["parameters"].includes(name)) {
    //   const savedEvent = Object.assign({}, value);
    //   setFilterValues((prevState) => {
    //     const existingVals = [...prevState[name]];
    //     const existingIndex = existingVals.indexOf(
    //       +savedEvent.currentTarget.dataset.value
    //     );
    //     existingIndex > -1
    //       ? existingVals.splice(existingIndex, 1)
    //       : existingVals.push(+savedEvent.currentTarget.dataset.value);
    //
    //     return {
    //       ...prevState,
    //       [name]: existingVals,
    //     };
    //   });
    // } else
    if (["parameterGroups", "locationTypes", "flowConditions"].includes(name)) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      //dates
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const { data: recordCount } = useQuery(
    ["download-user-filters-recordcounts", currentUser],
    async () => {
      if (currentUser?.sub) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters-recordcounts`,
            {
              uuid: currentUser?.sub,
            }
          );
          return data[0]?.sel_recordcount ?? 0;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (recordCount) {
      handleFilterValues("recordCount", recordCount);
    }
  }, [recordCount]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmitFilters = () => {
    setIsSubmitLoading(true);
    return (async () => {
      try {
        const user = currentUser?.sub || "public";
        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters`,
          {
            user_uuid: user,
            parameters: filterValues.parameters.map(
              (parameter) => parameter.parameterid
            ),
            locations: filterValues.locations.map(
              (location) => location.locationid
            ),
            flow_conditions: filterValues.flowConditions,
            start_date: filterValues.startDate,
            end_date: filterValues.endDate,
          }
        );

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters-recordcounts`,
            {
              uuid: user,
            }
          );
          handleFilterValues("recordCount", data[0]?.sel_recordcount ?? 0);
        } catch (err) {
          console.error(err);
        }

        doToast("success", "New filters were applied to the database");
        setIsSubmitLoading(false);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsSubmitLoading(false);
      }
    })();
  };

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = () => {
    setIsExportLoading(true);
    async function send() {
      try {
        let { data: timeseriesData } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/download-user-selected-data`,
          {
            uuid: currentUser?.sub ?? "public",
          }
        );

        const timeseriesDataCsvString = [
          [
            `"Results for constituents: ${filterValues.parameters
              .map((parameter) => parameter.param_descrip)
              .join(", ")}"`,
          ],
          [
            `"Results for locations: ${filterValues.locations
              .map((location) => location.location_descrip)
              .join(", ")}"`,
          ],
          [
            `"Results for flow conditions: ${filterValues.flowConditions
              .map((condition) =>
                getFilterNameByIndex(
                  condition,
                  flowConditions,
                  "flow_conditions",
                  "flow_conditionsid"
                )
              )
              .join(", ")}"`,
          ],
          [`"Time Series Data"`],
          [
            "Location Name",
            "Constituent",
            "Constituent Group",
            "Activity Date",
            "Data Value",
            "Units",
            "Flow Condition",
            "Location Type",
            "Data Source",
            "Taxa",
            "Depth",
            "Depth Units",
            "Depth Category",
            "Flag",
          ],
          ...timeseriesData.map((item) => [
            `"${item.location_name ?? ""}"`,
            `"${item.parameter ?? ""}"`,
            `"${item.parameter_group ?? ""}"`,
            `"${item.collect_date ?? ""}"`,
            `"${item.result ?? ""}"`,
            `"${item.result_units ?? ""}"`,
            `"${item.flow_condition ?? ""}"`,
            `"${item.location_type ?? ""}"`,
            `"${item.data_source ?? ""}"`,
            `"${item.taxa ?? ""}"`,
            `"${item.depth ?? ""}"`,
            `"${item.depth_units ?? ""}"`,
            `"${item.depth_category ?? ""}"`,
            `"${item.flag ?? ""}"`,
          ]),
        ]
          .map((e) => e.join(","))
          .join("\n");

        const a = document.createElement("a");
        a.href =
          "data:attachment/csv," + encodeURIComponent(timeseriesDataCsvString);
        a.target = "_blank";
        a.download = `Time Series Data.csv`;
        document.body.appendChild(a);
        a.click();
        // return csvString;

        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Query & Download</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        {locations && (
          <Grid item xs={12} md={12} lg={12} xl={5}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4" ml={2}>
                  Map
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MapContainer>
                  <QueryAndDownloadMap
                    locations={filterValues.locations.map(
                      (location) => location.locationid
                    )}
                  />
                </MapContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {hasParametersLoaded && hasLocationsLoaded && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "694px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Location Filter Groups
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MultiSelect
                            size="small"
                            fullWidth
                            data={locationTypes}
                            valueField="location_type"
                            displayField="location_type"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("locationTypes", e.target);
                              }
                            }}
                            value={filterValues.locationTypes}
                            name="locationTypes"
                            label="Location Types"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Locations
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Button
                                disabled={!locations.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      locations: locations,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                disabled={!locations.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("locations")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                disabled={!locations.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      locations: locations.filter(
                                        (location) => location.is_ccbwqa
                                      ),
                                    };
                                  })
                                }
                              >
                                * Select Only CCBWQA
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={3}>
                          <Autocomplete
                            size="small"
                            limitTags={3}
                            multiple
                            disableCloseOnSelect
                            id="locations"
                            name="locations"
                            options={locations}
                            value={cleanFilter(
                              filterValues.locations,
                              locations,
                              "locationid"
                            )}
                            getOptionLabel={(option) => {
                              return option.location_descrip;
                            }}
                            // getOptionSelected={(option, value) =>
                            //   option.value === value.value
                            // }
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              handleFilterValues("locations", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                color="primary"
                                variant="outlined"
                                label="Locations"
                              />
                            )}
                            renderOption={(props) => {
                              const {
                                is_ccbwqa,
                                location_descrip,
                                ...restProps
                              } = props;
                              return (
                                <div {...restProps}>
                                  {is_ccbwqa && (
                                    <LocationCity style={{ marginRight: 8 }} />
                                  )}
                                  {location_descrip}
                                </div>
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              return value.map((option, index) => (
                                <Chip
                                  icon={
                                    option.is_ccbwqa ? (
                                      <LocationCity />
                                    ) : undefined
                                  }
                                  label={option.location_descrip}
                                  {...getTagProps({ index })}
                                  size="small"
                                />
                              ));
                            }}
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Groups Filter
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              size="small"
                              fullWidth
                              data={parameterGroups}
                              valueField="param_group"
                              displayField="param_group"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterGroups",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterGroups}
                              name="parameterGroups"
                              label="Parameter Groups"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      parameters: parameters,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={3}>
                          <Autocomplete
                            size="small"
                            limitTags={3}
                            multiple
                            disableCloseOnSelect
                            id="parameters"
                            name="parameters"
                            options={parameters}
                            value={cleanFilter(
                              filterValues.parameters,
                              parameters,
                              "parameterid"
                            )}
                            getOptionLabel={(option) => {
                              return option.param_descrip;
                            }}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              handleFilterValues("parameters", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                color="primary"
                                variant="outlined"
                                label="Parameters"
                              />
                            )}
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Flow Conditions Filter
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              size="small"
                              fullWidth
                              data={flowConditions}
                              valueField="flow_conditionsid"
                              displayField="flow_conditions"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "flowConditions",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.flowConditions}
                              name="flowConditions"
                              label="Flow Conditions"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Time Period Selection
                      </Typography>
                      <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                              marginRight: "12px",
                            }}
                          >
                            <KeyboardDatePicker
                              size="small"
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="start-date-picker"
                              label="Start Date"
                              value={filterValues.startDate}
                              onChange={(date) =>
                                handleFilterValues("startDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change start date",
                              }}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                            }}
                          >
                            <KeyboardDatePicker
                              size="small"
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="end-date-picker"
                              label="End Date"
                              value={filterValues.endDate}
                              onChange={(date) =>
                                handleFilterValues("endDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change end date",
                              }}
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Box mt={2} style={{ width: "100%" }}>
                        {isSubmitLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            disabled={
                              !filterValues.parameters.length ||
                              !filterValues.locations.length ||
                              !filterValues.flowConditions.length ||
                              !filterValues.startDate ||
                              !filterValues.endDate ||
                              isExportLoading
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={() => handleSubmitFilters()}
                          >
                            Submit Filters
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Download Records
              </Typography>
            </AccordionSummary>
            <Box ml={3} mr={3} mb={3}>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={9} style={{ display: "flex" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography mr={2}>
                        Recordcount for Selected Data:
                      </Typography>
                      <Typography variant="h6" align="left">
                        <RecordsAvailableChip
                          variant="outlined"
                          color="primary"
                          label={filterValues.recordCount || 0}
                        />
                      </Typography>
                      {filterValues?.recordCount > 110000 && (
                        <Typography variant="subtitle2" color="error" ml={2}>
                          Maximum records for download is{" "}
                          <strong>110,000</strong>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    {isExportLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        disabled={
                          filterValues?.recordCount === "" ||
                          filterValues?.recordCount === 0 ||
                          filterValues?.recordCount > 110000 ||
                          isSubmitLoading
                        }
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleExportClick}
                      >
                        <Typography
                          variant="h6"
                          style={{ textTransform: "uppercase" }}
                        >
                          Download
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(QueryAndDownload);
