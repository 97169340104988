import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;

  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled.ul`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
  const existingFeatureKeys = {};
  // Because features come from tiled vector data, feature geometries may be split
  // or duplicated across tile boundaries and, as a result, features may appear
  // multiple times in query results.
  //concat two ids to make a unique id
  return array.filter((el) => {
    if (
      existingFeatureKeys[
        el[comparatorProperty1] + el.layer[comparatorProperty2]
      ]
    ) {
      return false;
    } else {
      existingFeatureKeys[
        el[comparatorProperty1] + el.layer[comparatorProperty2]
      ] = true;
      return true;
    }
  });
}

const Popup = ({
  features,
  layers,
  height = "200px",
  width = "380px",
  size = "medium",
}) => {
  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = layers?.find(
      (layer) => layer?.id === feature?.layer?.id
    )?.lreProperties?.popup?.excludeFields;
    setExcludeFields(excludedFields || []);
  }, [feature, layers]);

  const popupData = excludeFields
    ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
        //MJB also removing entry if the value is an empty string, null, or undefined
        if (
          !excludeFields.includes(key) &&
          value !== "" &&
          !isNullOrUndef(value)
        ) {
          acc.push([key, value]);
        }
        return acc;
      }, [])
    : Object.entries(feature?.properties);

  const [titleField, setTitleField] = useState("");

  useEffect(() => {
    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;
    setTitleField(
      (title &&
        feature?.properties[title] &&
        `${feature?.properties[title]} (${titleize(
          feature?.layer?.source
        )})`) ||
        titleize(feature?.layer?.source)
    );
  }, [feature, layers]);

  if (!popupData) return null;
  return (
    <>
      <h2 style={{ width: width }}>{titleField}</h2>
      <PopupWrap height={height} width={width}>
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value]) => {
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>{titleize(key)}</strong>
                  </PopupCell>
                  <PopupCell>
                    {typeof value === "string" && value.startsWith("<a ") ? (
                      <PopupUl>
                        {value.split(",").map((item) => (
                          <li key={item}>{parse(item)}</li>
                        ))}
                      </PopupUl>
                    ) : typeof value === "string" && value.startsWith('["') ? (
                      value.replace(/["{}[\]]/g, "").replaceAll(",", ", ")
                    ) : (
                      formatBooleanTrueFalse(value)
                    )}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={size}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default Popup;
