/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Activity,
  Archive,
  Database,
  FileText,
  Monitor,
  Users,
  Map as MapIcon,
  BookOpen,
  Download,
} from "react-feather";

import Blank from "../pages/pages/Blank";
import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import { CrudProvider } from "../CrudProvider";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import PrecipDailyAndCumulative from "../pages/dataAccess/timeseriesGraphs/PrecipDailyAndCumulative";
import StreamLoggerDataViewer from "../pages/dataAccess/timeseriesGraphs/StreamLoggerDataViewer";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import QueryAndDownload from "../pages/dataAccess/QueryAndDownload/QueryAndDownload";
import SitesSummaryTable from "../pages/dataAccess/reports/SitesSummaryTable";
import StreamLoggerIFrame from "../pages/dataAccess/timeseriesGraphs/StreamLoggerIFrame";
import PublicMapIFrame from "../pages/publicMap/publicMapIFrame";
import PublicMapIFramePAP from "../pages/publicMap/publicMapIFramePAP";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: item.sidebarName ?? inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

const getCrudRoutes = (list) => {
  return list.map((item) => {
    const config = require(`../pages/models/${item.name}Config`);
    const slug = inflector.dasherize(inflector.underscore(item.name));

    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      component: CrudIndexPage,
      provider: CrudProvider,
      config,
      crud: [
        {
          path: `/models/${slug}/:id`,
          name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
        {
          path: `/models/${slug}/add`,
          name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
      ],
    };
  });
};

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

// const dataManagementRoutes = {
//   header: "Data Management",
//   id: "Rolodex",
//   icon: <CreditCard />,
//   path: "/data-access/rolodex",
//   name: "Rolodex",
//   component: Blank,
// };

const dataEntryRoutes = {
  header: "Data Access",
  id: "Time Series",
  icon: <Activity />,
  children: [
    {
      path: "/data-access/time-series/precipitation",
      name: "Precipitation",
      component: PrecipDailyAndCumulative,
    },
    {
      path: "/data-access/time-series/stream-logger-data-viewer",
      name: "Stream Logger Data Viewer",
      component: StreamLoggerDataViewer,
    },
    {
      path: "/data-access/time-series/time-series-comparisons",
      name: "Time Series Comparisons",
      component: TimeSeriesComparison,
    },
  ],
};

const publicMapIFrame = {
  id: "Public Map iFrame",
  icon: <Download />,
  path: "/data-access/public-map-i-frame",
  name: "Public Map iFrame",
  component: PublicMapIFrame,
};

const publicMapIFramePAP = {
  id: "Public Map iFrame PAP",
  icon: <Download />,
  path: "/data-access/public-map-i-frame-pap",
  name: "Public Map iFrame PAP",
  component: PublicMapIFramePAP,
};

const streamLoggerIFrame = {
  id: "Stream Logger iFrame",
  icon: <Download />,
  path: "/data-access/stream-logger-i-frame",
  name: "Stream Logger iFrame",
  component: StreamLoggerIFrame,
};

const queryAndDownloadRoute = {
  id: "Query and Download",
  icon: <Download />,
  path: "/data-access/query-and-download",
  name: "Query and Download",
  component: QueryAndDownload,
};

const reportsRoutes = {
  id: "Reports",
  icon: <FileText />,
  children: [
    {
      path: "/data-access/reports/site-summary-table",
      name: "Site Summary Table",
      component: SitesSummaryTable,
    },
  ],
};

const publicMapRoutes = {
  header: "Map Resources",
  id: "Interactive Map",
  icon: <MapIcon />,
  path: ROUTES.PUBLIC_MAP,
  name: "Interactive Map",
  component: PublicMap,
};

const publicFilesRoutes = {
  id: "Public Files",
  header: "Resources",
  icon: <Archive />,
  path: "/data-access/resources/public-files",
  name: "Public Files",
  component: Blank,
};

const ExternalDrainageLinkRoute = {
  id: "Cherry Creek Major Drainageway Plan",
  link: "external",
  icon: <BookOpen style={{ width: "32px" }} />,
  path: "https://storymaps.arcgis.com/stories/05a3e9dfd98548878cbf20433818f3fe",
  name: "Cherry Creek Major Drainageway Plan",
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

// const mainRoutes = {
//   header: "Dashboards",
//   id: "Water Quality Dashboard",
//   path: "/dashboard",
//   icon: <Home />,
//   component: Default,
//   children: null,
//   containsHome: true,
// };

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  // mainRoutes,
  dataEntryRoutes,
  queryAndDownloadRoute,
  reportsRoutes,
  publicFilesRoutes,
  ExternalDrainageLinkRoute,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  ...modelCrudRoutes,
  publicMapRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [
  landingRoutes,
  streamLoggerIFrame,
  publicMapIFrame,
  publicMapIFramePAP,
];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  // mainRoutes,
  ...crudSidebarMenu,
  dataEntryRoutes,
  queryAndDownloadRoute,
  reportsRoutes,
  publicMapRoutes,
  publicFilesRoutes,
  ExternalDrainageLinkRoute,
];
