import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  AppBar as MuiAppBar,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Toolbar,
  List as MuiList,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";
import ThemesToggle from "./ThemesToggle";

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const List = styled(MuiList)`
  display: flex;
  justify-content: end;
  margin-right: 12px;
`;

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.sidebar.header.color};
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>

          <Grid item xs>
            <List>
              <ListItem
                button={true}
                component="a"
                href="https://ccbwqportal.org/"
              >
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                button={true}
                component="a"
                href="https://ccbwqportal.org/annual-report/2022/key-takeaways"
              >
                <ListItemText primary="Annual Reports" />
              </ListItem>
              <ListItem
                button={true}
                component="a"
                href="https://ccbwqportal.org/accessibility"
              >
                <ListItemText primary="Accessibility" />
              </ListItem>
            </List>
          </Grid>

          <Grid item>
            <ThemesToggle />
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default withTheme(AppBarComponent);
