import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Box, Paper, Typography } from "@material-ui/core";

import Map from "./map";
import WellStylesControl from "./controls/wellStylesControl";
import Search from "./filters/search";
import FilterControl from "./filters/filterControl";
import Filter from "./filters/filter";

import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";
import useLayerStyles from "./hooks/useLayerStyles";
import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import GraphModeToggle from "./controls/graphModeToggle";
import DataViz from "./components/DataViz";
import { useWaterQualitySummary } from "./hooks/useWaterQualitySummary";
import { useWaterQualityTimeSeries } from "./hooks/useWaterQualityTimeSeries";
import { useBenchmarkScaleColors } from "./hooks/useBenchmarkScaleColors";
import LegendControl from "./controls/legendControl";
import Legend from "./components/Legend";
import PeriodOfRecordInputs from "./components/periodOfRecordInputs";

const FiltersBar = styled(Paper)`
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-grow: 100;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const GraphModeFilterContainer = styled.div`
  width: 125px;
`;

const getMoreFiltersCount = (filterValues) => {
  const keys = ["hasTelem", "isActive", "isCcbwqa"];
  return keys.filter((key) => filterValues[key].value).length;
};

const PublicMap = () => {
  const mapContainer = useRef(null);

  const [mode, setMode] = useState("map-explorer");
  const [resultsPanelVisible, setResultsPanelVisible] = useState(false);
  const [legendVisible, setLegendVisible] = useState(false);

  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateBasemap,
    eventsRegistered,
    currentPapFeatures,
    updateLayerOpacity,
    selectedMonitoringLocation,
    setSelectedMonitoringLocation,
  } = useMap({
    ref: mapContainer,
    mapConfig: INIT_MAP_CONFIG,
    swapVisibility: [],
    setResultsPanelVisible,
    mode,
  });

  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    filtersData,
    handleUpdateDefaultFiltersModeToggle,
  } = useFilters({
    onFilterChange: updateLayerFilters,
    mode,
    eventsRegistered,
  });

  const {
    data: wqSummaryData,
    selectedLocationData: selectedLocationWqSummaryData,
    isLoading: isWqSummaryLoading,
    refetch: refetchWqSummary,
  } = useWaterQualitySummary({
    locationId: selectedMonitoringLocation?.locationid,
    parameterIds: filterValues?.parameters?.value,
    flow: filterValues?.flow?.value,
  });

  const { data: wqTimeSeriesData, isLoading: isWqTimeSeriesLoading } =
    useWaterQualityTimeSeries({
      locationId: selectedMonitoringLocation?.locationid,
      parameterIds: filterValues?.parameters?.value,
    });

  const { data: benchmarkScaleColors } = useBenchmarkScaleColors();

  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    analysis: filterValues?.analysis?.value,
    flow: filterValues?.flow?.value,
    parameters: filterValues?.parameters?.value,
    benchmarkScaleColors,
    benchmarkData: wqSummaryData,
    onLayerStyleChange: updateLayerStyles,
    filtersData,
  });

  const handleSearchSelect = (result) => {
    map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
  };

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = ["Print PDF", "Save PNG"];
  const handleSplitButtonClick = (index) => {
    if (![0, 1].includes(index)) return;

    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  return (
    <>
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}
      <FiltersBar>
        <FiltersSectionRow>
          <FiltersContainer>
            <Search onSelect={handleSearchSelect} />
          </FiltersContainer>
        </FiltersSectionRow>

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="132px"
              appliedCount={filterValues?.locationTypes?.value?.length}
              label="Loc Types"
            >
              <Filter
                label="Loc Types"
                name="locationTypes"
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={filterValues?.locationTypes?.options}
                type={filterValues?.locationTypes?.type}
                value={filterValues?.locationTypes?.value}
              />
            </FilterControl>
            <FilterControl
              width="152px"
              appliedCount={filterValues?.parameterGroups?.value?.length}
              label="Param Groups"
            >
              <Filter
                label="Param Groups"
                name="parameterGroups"
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={filterValues?.parameterGroups?.options}
                type={filterValues?.parameterGroups?.type}
                value={filterValues?.parameterGroups?.value}
              />
            </FilterControl>
            <FilterControl
              appliedCount={getMoreFiltersCount(filterValues)}
              label="More Filters"
              width="130px"
            >
              <Box display="flex" flexDirection="column">
                <Filter
                  label="Has Telemetry"
                  name="hasTelem"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasTelem?.value}
                />
                <Filter
                  label="Is Active"
                  name="isActive"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.isActive?.value}
                />
                <Filter
                  label="Is CCBWQA"
                  name="isCcbwqa"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.isCcbwqa?.value}
                />
              </Box>
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        {mode === "graph-mode" && (
          <FiltersSection>
            <FiltersContainer>
              <GraphModeFilterContainer>
                <Filter
                  label="Min Records"
                  name="min_records"
                  onChange={handleFilterValues}
                  type={filterValues?.min_records?.type}
                  value={filterValues?.min_records?.value}
                />
              </GraphModeFilterContainer>

              <GraphModeFilterContainer>
                <Filter
                  label="Analysis"
                  name="analysis"
                  onChange={handleFilterValues}
                  options={filterValues?.analysis?.options}
                  type={filterValues?.analysis?.type}
                  value={filterValues?.analysis?.value}
                />
              </GraphModeFilterContainer>

              <GraphModeFilterContainer>
                <Filter
                  label="Flow"
                  name="flow"
                  onChange={handleFilterValues}
                  options={filterValues?.flow?.options}
                  type={filterValues?.flow?.type}
                  value={filterValues?.flow?.value}
                />
              </GraphModeFilterContainer>

              <PeriodOfRecordInputs
                filterValues={filterValues}
                handleFilterValues={handleFilterValues}
                refetchWqSummary={refetchWqSummary}
              />
            </FiltersContainer>
          </FiltersSection>
        )}

        <FiltersSection>
          <FiltersContainer>
            <FilterControl width="185px" label={`Color by ${activeStyle.name}`}>
              <Typography variant="subtitle1" gutterBottom>
                Color Data Points by
              </Typography>
              <WellStylesControl
                mode={mode}
                label="Color Data Points by"
                name="wellStyles"
                onChange={handleActiveStyle}
                options={styleOptions}
                value={activeStyle.id}
              />
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <SplitButton
              options={splitButtonOptions}
              handleExportClick={handleSplitButtonClick}
            />
            <PrintReportDialog
              downloadCallback={handlePrintMapClick}
              setPrintReportDialogOpen={setPrintReportDialogOpen}
              printReportDialogOpen={printReportDialogOpen}
              title={title}
              setTitle={setTitle}
            />
          </FiltersContainer>
        </FiltersSection>
      </FiltersBar>
      <Map ref={mapContainer}>
        {eventsRegistered && (
          <MainControl
            map={map}
            features={currentPapFeatures}
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            filterValues={filterValues}
            handleFilterValues={handleFilterValues}
            onSelectAll={handleSelectAll}
            onSelectNone={handleSelectNone}
            layers={layers}
            onBasemapChange={updateBasemap}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            mode={mode}
          />
        )}
        {mode === "graph-mode" && (
          <>
            <LegendControl
              open={legendVisible}
              onToggle={() => setLegendVisible(!legendVisible)}
              top={98}
            />
            {legendVisible && <Legend legendColors={benchmarkScaleColors} />}

            <DataViz
              open={resultsPanelVisible}
              selectedMonitoringLocation={selectedMonitoringLocation}
              analyticsResults={selectedLocationWqSummaryData}
              benchmarkScaleColors={benchmarkScaleColors}
              filterValues={filterValues}
              isAnalyticsTableDataLoading={isWqSummaryLoading}
              timeSeriesResults={wqTimeSeriesData}
              isTimeSeriesTableDataLoading={isWqTimeSeriesLoading}
              onClose={() => setResultsPanelVisible(false)}
            />
          </>
        )}

        {/*TODO add is graph data loaded check*/}
        {eventsRegistered && (
          <GraphModeToggle
            open={mode === "graph-mode"}
            handleClick={() => {
              if (mode === "graph-mode") {
                handleUpdateDefaultFiltersModeToggle();
                handleActiveStyle("locationTypes");
                setLegendVisible(false);
                setResultsPanelVisible(false);
                setMode("map-explorer");
              } else {
                handleUpdateDefaultFiltersModeToggle();
                handleActiveStyle("wqData");
                setLegendVisible(true);
                setResultsPanelVisible(true);
                setMode("graph-mode");
                setSelectedMonitoringLocation(null);
              }
            }}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </>
  );
};

export default PublicMap;
