import MultiSelect from "../multiSelect";
import Switch from "../switch";
import SimpleSelect from "../simpleSelect";
import SimpleNumber from "../simpleNumber";
import Date from "../date";

const filtersLookup = {
  "simple-select": SimpleSelect,
  "multi-select": MultiSelect,
  "multi-select-array": MultiSelect,
  boolean: Switch,
  "simple-number": SimpleNumber,
  date: Date,
};

const Filter = ({ type, ...rest }) => {
  const FilterComponent = filtersLookup[type];
  if (!FilterComponent) return null;
  return <FilterComponent {...rest} />;
};

export default Filter;
