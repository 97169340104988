import { useEffect } from "react";
import debounce from "lodash.debounce";

export const useMapResize = ({ map, ref }) => {
  //MJB adding some logic to resize the map when the map container ref size changes
  //ResizeObserver watches for changes in bounding box for ref
  //debounce delays the resize function by 100ms
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(ref.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map, ref]);
};
