import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { coordinatesGeocoder } from "../useMap/mapUtils";
import { RulerControl } from "mapbox-gl-controls";

export const useMapControls = ({ map }) => {
  const [areControlsAdded, setAreControlsAdded] = useState(false);

  useEffect(() => {
    const shouldAddControls = !!map && !areControlsAdded;

    if (shouldAddControls) {
      //top left controls
      map.addControl(new mapboxgl.NavigationControl(), "top-left");
      map.addControl(new mapboxgl.FullscreenControl(), "top-left");
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
        }),
        "top-left"
      );
      map.addControl(new ResetZoomControl(), "top-left");

      //top right controls
      map.addControl(
        new MapboxGeocoder({
          accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
          localGeocoder: coordinatesGeocoder,
          zoom: 16,
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          placeholder: "Address/Coords Search",
        }),
        "top-right"
      );

      //bottom left controls
      map.addControl(
        new mapboxgl.ScaleControl({ unit: "imperial", maxWidth: 250 }),
        "bottom-left"
      );
      map.addControl(
        new RulerControl({
          units: "feet",
          labelFormat: (n) => `${n.toFixed(2)} ft`,
        }),
        "bottom-left"
      );

      setAreControlsAdded(true);
    }
  }, [map, areControlsAdded]); // eslint-disable-line
};
