import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";

const Date = ({
  label,
  name,
  onChange,
  value,
  format = "MM/dd/yyyy",
  width = "125px",
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        style={{ width: width }}
        size="small"
        autoOk
        inputVariant="outlined"
        format={format}
        label={label}
        name={name}
        value={value}
        //synthetic event
        onChange={(date) =>
          onChange({
            target: {
              name: name,
              value: date,
            },
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
};

export default Date;
