import React from "react";

import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Loader from "../../../../components/Loader";
import SubmitIcon from "@material-ui/icons/Publish";
import { RefreshCw } from "react-feather";
import Filter from "../../filters/filter";
import useDynamicPeriodOfRecord from "../../hooks/useDynamicPeriodOfRecord";

const PeriodOfRecordInputs = ({
  filterValues,
  handleFilterValues,
  refetchWqSummary,
}) => {
  const { handleSubmit, isSubmitLoading } = useDynamicPeriodOfRecord({
    startDate: filterValues.startDate.value,
    endDate: filterValues.endDate.value,
    refetchWqSummary,
  });

  return (
    <>
      <Filter
        label="Start Date"
        name="startDate"
        onChange={handleFilterValues}
        type={filterValues?.startDate?.type}
        value={filterValues?.startDate?.value}
      />
      <Filter
        label="End Date"
        name="endDate"
        onChange={handleFilterValues}
        type={filterValues?.endDate?.type}
        value={filterValues?.endDate?.value}
      />
      <Tooltip
        placement="bottom"
        title={
          <Box display="flex" justifyContent="center" alignItems="center">
            <InfoIcon color="primary" />
            <Typography
              component="span"
              variant="h6"
              style={{ textAlign: "center" }}
            >
              Click to submit dates to recalculate benchmark statistics
            </Typography>
            <InfoIcon color="primary" />
          </Box>
        }
        arrow
      >
        <span>
          {isSubmitLoading ? (
            <Loader />
          ) : (
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={handleSubmit}
            >
              <SubmitIcon />
            </IconButton>
          )}
        </span>
      </Tooltip>
      <Tooltip title="Quick-set dates to baseline study period">
        <IconButton
          color="primary"
          aria-label="reset to baseline dates"
          onClick={() => {
            handleFilterValues({
              target: {
                name: "startDate",
                value: "10/01/11",
              },
            });
            handleFilterValues({
              target: {
                name: "endDate",
                value: "9/30/21",
              },
            });
          }}
        >
          <RefreshCw />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PeriodOfRecordInputs;
