import React, { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    backgroundColor: "rgba(49, 49, 49, 1)",
    borderRadius: 4,
    zIndex: 1200,
    padding: 15,
    maxWidth: 300,
  },
  popoverTitle: {
    color: theme.palette.primary.main,
  },
  popoverSubtitle: {
    color: "#ffffff",
  },
  popoverListItem: {
    marginTop: 5,
    display: "flex",
    color: "#ffffff",
  },
  popoverSymbolPoint: {
    border: "1.5px solid #333333",
    borderRadius: "50%",
    width: 20,
    height: 20,
  },
  popoverText: {
    marginLeft: 5,
    fontSize: "0.8rem",
  },
  noDataText: {
    color: "#c2c2c2",
    marginTop: 5,
  },
});

const createColorItem = (condition, name, color) =>
  condition ? { name, color } : null;

const BenchmarkPopover = React.memo(({ classes, data }) => {
  const adjustedUnits = useMemo(
    () => (data.units === "None" ? "" : data.units),
    [data.units]
  );

  // "Total Alkalinity",
  // "pH",
  // "Water Temperature",
  // "Conductivity",
  // "Dissolved Oxygen",
  // "Light Transmittance [99% Attenuation]",
  // "Light Transmittance [Secchi Depth]",
  const specialParameters = useMemo(() => [73, 7, 8, 1902, 3, 10, 6], []);

  const popoverColors = useMemo(() => {
    let items = [
      createColorItem(
        data.bmk_line3 !== null,
        `>${data.bmk_line3} ${adjustedUnits}`,
        data.bmk_color4
      ),
      createColorItem(
        data.bmk_line2 !== null,
        `${data.bmk_line2} - ${data.bmk_line3} ${adjustedUnits}`,
        data.bmk_color3
      ),
      createColorItem(
        data.bmk_line1 !== null,
        `${data.bmk_line1} - ${data.bmk_line2} ${adjustedUnits}`,
        data.bmk_color2
      ),
      createColorItem(
        data.bmk_line0 !== null,
        `${data.bmk_line0} - ${data.bmk_line1} ${adjustedUnits}`,
        data.bmk_color1
      ),
    ].filter(Boolean);

    const defaultItem = data.bmk_color0
      ? {
          name: specialParameters.includes(data.parameter_index)
            ? `<${data.bmk_line0} ${adjustedUnits}`
            : `${data.parameter} not detected`,
          color: data.bmk_color0,
        }
      : items.length === 0
      ? { name: "No benchmarks available", color: "cornflowerBlue" }
      : null;

    if (defaultItem) items.push(defaultItem);

    return items;
  }, [data, adjustedUnits, specialParameters]);

  if (popoverColors.length === 0) {
    return (
      <div className={classes.root}>
        <Typography className={classes.noDataText}>
          No benchmarks available
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        color="secondary"
        className={classes.popoverTitle}
      >
        {`${data.parameter} Benchmarks`}
      </Typography>
      {popoverColors.map((el, index) => (
        <div className={classes.popoverListItem} key={index}>
          <div
            className={classes.popoverSymbolPoint}
            style={{ backgroundColor: el.color }}
          />
          <div className={classes.popoverText}>{el.name}</div>
        </div>
      ))}
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(BenchmarkPopover);
