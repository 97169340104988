export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-104.81615, 39.5156];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 10,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "locations-circle";
export const WELLS_LABELS_LAYER_ID = "locations-symbol";

export const INIT_FILTER_VALUES = {
  locationTypes: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "location_type",
    options: [],
    type: "multi-select",
    value: [],
  },
  parameterGroups: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "parameter_groups",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  parameters: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "parameterid_array_alldata",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  analysis: {
    type: "simple-select",
    options: [],
    value: "",
  },
  flow: {
    type: "simple-select",
    options: [],
    value: "",
  },
  startDate: {
    type: "date",
    value: null,
  },
  endDate: {
    type: "date",
    value: null,
  },
  min_records: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "recordcount_alldata",
    type: "simple-number",
    value: 0,
  },
  hasTelem: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "telem",
    type: "boolean",
    value: false,
  },
  isActive: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "active",
    type: "boolean",
    value: true,
  },
  isCcbwqa: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_ccbwqa",
    type: "boolean",
    value: true,
  },
  search: {
    layerId: WELLS_LAYER_ID,
    type: "select",
    value: "attributes_search",
  },
};

const layerId = "locations-circle";
export const INIT_STYLE_VALUES = {
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "location_type",
    name: "Loc Types",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "location_type"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  parameterGroups: {
    id: "parameterGroups",
    layerId,
    layerFieldName: "parameter_group_fav",
    name: "Param Groups",
    options: [],
    type: "multi-select-array",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "parameter_group_fav"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  wqData: {
    id: "wqData",
    layerId,
    layerFieldName: "",
    name: "WQ Data",
    // This gets updated dynamically
    paint: {
      "circle-stroke-width": 2,
      "circle-stroke-color": "black",
      "circle-color": [
        "match",
        ["get", "summary_benchmark_scale_median"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  default: {
    id: "default",
    layerId,
    layerFieldName: "",
    name: "Default",
    paint: {
      "circle-color": "#1e8dd2",
      "circle-stroke-width": 2,
      "circle-stroke-color": "black",
    },
  },
};
