import { useQuery } from "react-query";
import axios from "axios";

const BENCHMARK_COLOR_DEFAULTS = [
  { legend_text: `Above secondary benchmark`, dot_symbol_color: `red` },
  { legend_text: `Above benchmark`, dot_symbol_color: `orange` },
  { legend_text: `Approaching benchmark`, dot_symbol_color: `yellow` },
  { legend_text: `Below benchmark`, dot_symbol_color: `MediumSeaGreen` },
  { legend_text: `Below benchmark`, dot_symbol_color: `PaleTurquoise` },
  {
    legend_text: `No benchmarks available`,
    dot_symbol_color: `cornflowerblue`,
  },
];

export function useBenchmarkScaleColors() {
  const { data, isError, isLoading } = useQuery(
    ["benchmark-scale-colors"],
    async () => {
      try {
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/benchmark-scale-colors`
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data?.data || BENCHMARK_COLOR_DEFAULTS,
    isError,
    isLoading,
  };
}
