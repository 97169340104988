import React from "react";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components/macro";
import Chips from "../../../../components/Chips";
import { spacing } from "@material-ui/system";
import Button from "@material-ui/core/Button";

const ChipsGroup = styled.div`
  ${spacing};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-left: 3px;
  margin-right: 3px;
`;

const FilterGroup = styled(Box)`
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
`;

const ParametersControl = ({
  filterValues,
  handleFilterValues,
  onSelectAll,
  onSelectNone,
  mode,
}) => {
  return (
    <FilterGroup p={1}>
      <ChipsGroup mb={2}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                disabled={!filterValues?.parameters?.options?.length}
                variant="outlined"
                color="secondary"
                fullWidth
                size="small"
                onClick={() => onSelectAll("parameters")}
              >
                + Select All
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={!filterValues?.parameters?.options?.length}
                variant="outlined"
                color="secondary"
                fullWidth
                size="small"
                onClick={() => onSelectNone("parameters")}
              >
                - Select None
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Chips
          data={filterValues}
          valueField="value"
          displayField="display"
          handleChipClick={(e) => {
            // Retrieve the closest ancestor (including the target itself)
            // that has the "data-value" attribute
            const chipElement = e.target.closest("[data-value]");

            if (chipElement) {
              const value = +chipElement.getAttribute("data-value");

              const fakeEvent = {
                target: {
                  name: "parameters",
                  value: value,
                },
              };

              handleFilterValues(fakeEvent);
            } else {
              // Handle the case where no appropriate element is found
              console.error(
                "No data-value attribute found in chip or its ancestors"
              );
            }
          }}
          type="parameter-type"
          activeChips={filterValues?.parameters?.value}
          tooltipField1="display"
          mode={mode}
        />
      </ChipsGroup>
    </FilterGroup>
  );
};

export default ParametersControl;
