import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const SimpleSelect = ({ label, name, onChange, options, value }) => {
  return (
    <TextField
      variant="outlined"
      select
      fullWidth
      size="small"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
    >
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.display}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SimpleSelect;
