import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { add } from "date-fns";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { dateFormatter, groupByValueArray, lineColors } from "../../../utils";

import Panel from "../../../components/panels/Panel";
import Map from "../../../components/map/Map";
import TimeseriesFilters from "../../../components/filters/TimeseriesFilters";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import Table from "../../../components/Table";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";

import axios from "axios";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { MultiSelect, Select } from "@lrewater/lre-react";
import useFetchData from "../../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 92px);
  width: 100%;
`;
const FiltersContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const MapContainer = styled.div`
  height: 300px;
  width: 100%;
`;
const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const StreamLoggerDataViewer = () => {
  const saveRef = useRef(null);

  const parameterOptions = [
    {
      name: "Flow",
      id: 2,
    },
    {
      name: "Stage",
      id: 1901,
    },
  ];

  const [locationsOptions] = useFetchData("list-locations-telem", [], false);

  const [selectedParameter, setSelectedParameter] = useState(
    parameterOptions[0].id
  );

  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    if (locationsOptions.length) {
      setSelectedLocations(locationsOptions.map((item) => item.locationid));
    }
  }, [locationsOptions]);

  const handleFilter = (name, event) => {
    if ("selectedParameter" === name) {
      setSelectedParameter(event.target.value);
    } else if ("selectedLocations" === name) {
      setSelectedLocations(event.target.value);
    }
  };

  //date filter defaults
  const defaultFilterValues = {
    previousDays: "",
    //oct 1 of last year (first of water year)
    startDate: new Date(new Date().getFullYear(), -3, 1),
    endDate: new Date(),
    checked: false,
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  //columns and fields to render on table
  const tableColumns = [
    {
      title: "Location",
      field: "location_name",
      width: "100%",
      filtering: false,
    },
    {
      title: "Parameter",
      field: "parameter",
      width: "100%",
      filtering: false,
    },

    // { title: "Parameter Id", field: "parameterid", width: "100%" },
    {
      title: "Report Date",
      field: "rdate",
      type: "date",
      render: (rowData) => {
        return dateFormatter(rowData.rdate, "MM/DD/YYYY");
      },
    },
    { title: "Daily Average", field: "daily_average", filtering: false },
    { title: "Daily Min", field: "daily_min", filtering: false },
    { title: "Daily Max", field: "daily_max", filtering: false },
    { title: "Units", field: "result_unit_name", filtering: false },
  ];

  const [graphData, setGraphData] = useState([]);
  const { isFetching, error, data } = useQuery(
    [
      `api/stream-logger-results-viewer-daily-stats-table/${selectedParameter}/${selectedLocations}`,
      selectedParameter,
      selectedLocations,
    ],
    async () => {
      if (selectedLocations?.length === 0) {
        setGraphData([]);
        setFilteredTableData([]);
        setFilteredMutatedGraphData([]);
        return null;
      }
      if (selectedParameter && locationsOptions?.length > 0) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/stream-logger-results-viewer-daily-stats-table/${selectedParameter}/${selectedLocations}`
          );
          const groupedDataArray = groupByValueArray(data, "rdate");

          setGraphData(groupedDataArray);

          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const uniqueLocations = useMemo(() => {
    if (data?.length) {
      const uniqueItemNames = [
        ...new Set(
          data.map((item) => {
            return item.location_name;
          })
        ),
      ];
      return uniqueItemNames;
    }
  }, [data]);

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState([]);
  //filtered data for graph, it filters selected locations
  //it keeps every date so the graph can still be panned and zoomed
  //it also mutates the data to be consumed by chartsJS
  //filtered data for table. if filters selected locations
  //it also filters by date
  const [filteredTableData, setFilteredTableData] = useState([]);
  useEffect(() => {
    if (data && graphData.length) {
      const tableFilterData =
        //if there is no value in the input and the prev is selected, yield every record
        filterValues.checked && filterValues.previousDays === ""
          ? data
          : //if the toggle is checked and it is not blank, yield x days
          filterValues.checked && filterValues.previousDays !== ""
          ? data.filter(
              (item) =>
                new Date(item.rdate) >=
                  add(new Date().getTime(), {
                    days: -filterValues.previousDays,
                  }) && new Date(item.rdate) <= new Date()
            )
          : //if the toggle is unchecked, yield date range
            data.filter(
              (item) =>
                add(new Date(item.rdate).getTime(), { days: 1 }) >=
                  filterValues.startDate &&
                new Date(item.rdate) <= filterValues.endDate
            );
      setFilteredTableData(tableFilterData);

      //mutate data for chartJS to use
      const defaultStyle = {
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        yAxisID: "yL",
        borderWidth: 2,
        tension: 0.75,
      };
      const mutatedGraphData = {
        labels: graphData.map((item) => item[0].rdate),
        units: graphData[0][0]?.result_unit_name,
        parameter: graphData[0][0]?.parameter,
        datasets: [
          ...uniqueLocations.map((location, i) => {
            return {
              units: graphData[0][0]?.result_unit_name,
              label: location,
              borderColor: Object.entries(lineColors)[i],
              backgroundColor: Object.entries(lineColors)[i],
              data: graphData.map(
                (item) =>
                  item.filter((arr) => arr.location_name === location)[0]
                    ?.daily_average ?? null
              ),
              ...defaultStyle,
            };
          }),
        ],
      };
      setFilteredMutatedGraphData(mutatedGraphData);
    }
  }, [data, graphData, filterValues]); //eslint-disable-line

  return (
    <>
      <Helmet title="Stream Logger Data Viewer" />
      <Typography variant="h3" gutterBottom display="inline">
        Stream Logger Data Viewer
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Stream Logger Data Viewer</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={7}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <Map
                  locationsToInclude={[
                    "PC-1",
                    "CT-P2",
                    "Lakeview Drive",
                    "CC-10",
                    "CT-P1",
                    "CC-7",
                    "CT-1",
                    "CT-2",
                  ]}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="date-filters"
              id="date-filters"
            >
              <Typography variant="h4" ml={2}>
                Date Filters{" "}
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <FiltersContainer>
                  <TimeseriesFilters
                    filterValues={filterValues}
                    changeFilterValues={changeFilterValues}
                  />
                </FiltersContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Graph
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TimeseriesContainer>
                  <Grid
                    container
                    pb={6}
                    mt={2}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        maxWidth: "150px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Select
                        name="parameter"
                        label="Parameter"
                        variant="outlined"
                        outlineColor="primary"
                        labelColor="primary"
                        valueField="id"
                        displayField="name"
                        data={parameterOptions}
                        value={selectedParameter}
                        onChange={(e) => handleFilter("selectedParameter", e)}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        maxWidth: "calc(100% - 150px - 70px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <MultiSelect
                        fullWidth
                        data={locationsOptions}
                        valueField="locationid"
                        displayField="location_name"
                        onChange={(e) => {
                          if (e.target.value.includes("all/none")) {
                            return null;
                          } else {
                            handleFilter("selectedLocations", e);
                          }
                        }}
                        value={selectedLocations}
                        name="locations"
                        label="Locations"
                        variant="outlined"
                        fillColor="primary"
                        outlineColor="primary"
                        labelColor="primary"
                        margin="normal"
                        width="264px"
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "6px",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        width: "53px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Stream Logger Data Viewer"
                      />
                    </Grid>
                  </Grid>
                  <TableWrapper>
                    <TimeseriesLineChart
                      data={filteredMutatedGraphData}
                      error={error}
                      isLoading={isFetching}
                      filterValues={filterValues}
                      yLLabel={`${filteredMutatedGraphData.parameter} (${filteredMutatedGraphData.units})`}
                      ref={saveRef}
                      tooltipFormat="MM-DD-YYYY"
                      xLabelFormat="MM-DD-YYYY"
                      // maxL={
                      //   selectedParameter === 2
                      //     ? 130
                      //     : selectedParameter === 1901
                      //     ? 5
                      //     : null
                      // }
                    />
                  </TableWrapper>
                </TimeseriesContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    options={{ filtering: true }}
                    // isLoading={isLoading}
                    label="Stream Logger Data Viewer"
                    columns={tableColumns}
                    data={filteredTableData}
                    height="600px"
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default StreamLoggerDataViewer;
