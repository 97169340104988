import { useEffect, useState } from "react";
import { INIT_STYLE_VALUES } from "../../constants";
import { scaleOrdinal, scaleSequential } from "d3-scale";
import { interpolateRainbow } from "d3-scale-chromatic";

const useLayerStyles = ({
  analysis,
  flow,
  parameters,
  benchmarkScaleColors,
  benchmarkData,
  onLayerStyleChange,
  filtersData,
}) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  const colors = (specifier) => {
    var n = (specifier.length / 6) | 0,
      colors = new Array(n),
      i = 0;
    while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
    return colors;
  };

  const colorsString20 =
    "1F77B4AEC7E8FF7F0EFFBB782CA02C98DF8AD62728FF98969467BDC5B0D58C564BC49C94E377C2F7B6D27F7F7FC7C7C7BCBD22DBDB8D17BECF9EDAE5";

  const buildScale = (values) => {
    const scale =
      values.length <= 20
        ? scaleOrdinal(colors(colorsString20))
        : scaleSequential(interpolateRainbow);

    const degree = 100 / values.length / 100;

    return values.reduce((acc, val, i) => {
      acc.push([val]);

      if (values.length <= 20) {
        acc.push(scale(val));
      } else {
        acc.push(scale(i * degree));
      }

      return acc;
    }, []);
  };

  useEffect(() => {
    if (filtersData) {
      setStyleValues((prevState) => ({
        ...prevState,
        locationTypes: {
          ...prevState.locationTypes,
          paint: {
            "circle-color": [
              "match",
              ["get", "location_type"],
              ...buildScale(
                filtersData.locationTypes.map((item) => item.display)
              ),
              "#fff",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
        parameterGroups: {
          ...prevState.parameterGroups,
          paint: {
            "circle-color": [
              "match",
              ["get", "parameter_group_fav"],
              ...buildScale(
                filtersData.parameterGroups.map((item) => item.display)
              ),
              "#fff",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
      }));
    }
  }, [filtersData]); //eslint-disable-line

  const [activeStyle, setActiveStyle] = useState(styleValues.locationTypes);

  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    const hasBenchmarkData = benchmarkData.length > 0;
    const hasBenchmarkScaleColors = benchmarkScaleColors.length > 0;
    const isWqStyleMode = name === "wqData";

    if (
      hasBenchmarkData &&
      hasBenchmarkScaleColors &&
      isWqStyleMode &&
      !!parameters?.length
    ) {
      const benchmarkField = `${flow}_bmk_${
        analysis === "median" ? "median" : "pctile"
      }`;

      const filteredBenchmarkData = benchmarkData?.filter(
        (item) =>
          ![null, undefined].includes(item[benchmarkField]) &&
          parameters.includes(item.parameter_index)
      );

      const benchmarksByScaleValue = benchmarkScaleColors.reduce(
        (acc, curr) => {
          acc[curr.bmk_value] = curr.dot_symbol_color;
          return acc;
        },
        {}
      );

      let locationsWithMaxBenchmark = new Map();
      filteredBenchmarkData.forEach((d) => {
        const existingRecord = locationsWithMaxBenchmark.get(d.location_name);

        if (existingRecord) {
          if (existingRecord[benchmarkField] < d[benchmarkField]) {
            locationsWithMaxBenchmark.set(d.location_name, d);
          }
        } else {
          locationsWithMaxBenchmark.set(d.location_name, d);
        }
      });

      const buildBenchmarkScale = (values) => {
        return values.map((value) => {
          const benchmarkValue = value[benchmarkField];

          return [
            [value.location_name],
            benchmarksByScaleValue[benchmarkValue],
          ];
        });
      };

      let updatedStyleValues = {};

      setStyleValues((prevState) => {
        updatedStyleValues = {
          ...prevState,
          wqData: {
            ...prevState.wqData,
            paint: {
              "circle-color": !!locationsWithMaxBenchmark.size
                ? [
                    "match",
                    ["get", "location_name"],
                    ...buildBenchmarkScale([
                      ...locationsWithMaxBenchmark.values(),
                    ]).flat(),
                    benchmarksByScaleValue[-1],
                  ]
                : "black",
              "circle-stroke-width": 2,
              "circle-stroke-color": "black",
            },
          },
        };
        setActiveStyle(updatedStyleValues[name]);
        onLayerStyleChange(updatedStyleValues[name]);
        return updatedStyleValues;
      });
    } else {
      setActiveStyle(styleValues[name]);
      onLayerStyleChange(styleValues[name]);
    }
  };
  // TODO cleanup and dedupe logic
  useEffect(() => {
    const hasBenchmarkData = benchmarkData.length > 0;
    const hasBenchmarkScaleColors = benchmarkScaleColors.length > 0;
    const isWqStyleMode = activeStyle.id === "wqData";

    if (
      hasBenchmarkData &&
      hasBenchmarkScaleColors &&
      isWqStyleMode &&
      !!parameters?.length
    ) {
      const benchmarkField = `${flow}_bmk_${
        analysis === "median" ? "median" : "pctile"
      }`;

      const filteredBenchmarkData = benchmarkData?.filter(
        (item) =>
          ![null, undefined].includes(item[benchmarkField]) &&
          parameters.includes(item.parameter_index)
      );

      const benchmarksByScaleValue = benchmarkScaleColors.reduce(
        (acc, curr) => {
          acc[curr.bmk_value] = curr.dot_symbol_color;
          return acc;
        },
        {}
      );

      let locationsWithMaxBenchmark = new Map();
      filteredBenchmarkData.forEach((d) => {
        const existingRecord = locationsWithMaxBenchmark.get(d.location_name);

        if (existingRecord) {
          if (existingRecord[benchmarkField] < d[benchmarkField]) {
            locationsWithMaxBenchmark.set(d.location_name, d);
          }
        } else {
          locationsWithMaxBenchmark.set(d.location_name, d);
        }
      });

      const buildBenchmarkScale = (values) => {
        return values.map((value) => {
          const benchmarkValue = value[benchmarkField];

          return [
            [value.location_name],
            benchmarksByScaleValue[benchmarkValue],
          ];
        });
      };

      let updatedStyleValues = {};

      setStyleValues((prevState) => {
        updatedStyleValues = {
          ...prevState,
          wqData: {
            ...prevState.wqData,
            paint: {
              "circle-color": !!locationsWithMaxBenchmark.size
                ? [
                    "match",
                    ["get", "location_name"],
                    ...buildBenchmarkScale([
                      ...locationsWithMaxBenchmark.values(),
                    ]).flat(),
                    benchmarksByScaleValue[-1],
                  ]
                : "black",
              "circle-stroke-width": 2,
              "circle-stroke-color": "black",
            },
          },
        };
        setActiveStyle(updatedStyleValues.wqData);
        onLayerStyleChange(updatedStyleValues.wqData);
        return updatedStyleValues;
      });
    }
  }, [benchmarkScaleColors, benchmarkData, analysis, parameters, flow]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
