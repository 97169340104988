import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import Popup from "../../popup";
import useSources from "../useSources";
import useLayers from "../useLayers";
import { getUniqueFeatures, MapLogger } from "./mapUtils";
import { BASEMAP_STYLES } from "../../constants";
import createTheme from "../../../../theme";
import { ThemeProvider } from "styled-components/macro";
import { useSelector } from "react-redux";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import { useMapResize } from "../useMapResize";
import { useMapControls } from "../useMapControls";

const mapLogger = new MapLogger({
  enabled: process.env.NODE_ENV === "development",
  prefix: "Public Map",
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

/**
 * The `useMap` hook controls all of the Mapbox functionality. It controls
 * everything from rendering the map and popups to filtering layers to styling
 * layers.
 * The hook exposes the map instance in addition to a variety of handlers
 * responsible for applying filters and styles to the map
 * @param {object} ref a React ref for the map container
 * @param {*} mapConfig initial configuration options for the map
 * @param {*} forceVisibility optional array of layer ids to force visibility on
 * see https://docs.mapbox.com/mapbox-gl-js/api/map/
 */
const useMap = ({
  ref,
  mapConfig,
  swapVisibility = [],
  setResultsPanelVisible = () => {},
  mode = "map-explorer",
}) => {
  const theme = useSelector((state) => state.themeReducer);

  // Map related state
  const [map, setMap] = useState(null);
  const [activeBasemap, setActiveBasemap] = useState(BASEMAP_STYLES[0].style);
  const [eventsRegistered, setEventsRegistered] = useState(false);
  const firstLoadRef = useRef(false);

  // Data related state
  const [currentPapFeatures, setCurrentPapFeatures] = useState([]);
  const [dataAdded, setDataAdded] = useState(false);
  const [selectedMonitoringLocation, setSelectedMonitoringLocation] =
    useState(null);
  const [dataVizGraphType, setDataVizGraphType] = useState(null);

  // the use of useRef allows the mapbox click handler to have access
  // to the current value of mode, instead of its value when the click handler
  // was defined
  const modeRef = useRef(mode);
  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  // Define all refs
  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );

  // resize the map when the map container ref size changes
  useMapResize({ map, ref });

  // Add basic map controls
  useMapControls({ map });

  // Fetch a list of sources  and layers to add to the map
  const { sources } = useSources();
  const { layers, setLayers } = useLayers(swapVisibility);

  /**
   * Function responsible for initializing the map
   * Once the map is loaded, store a reference to the map in
   * our application state and update the map status
   */
  const initializeMap = useCallback(() => {
    if (ref?.current && !map?.loaded()) {
      const mapInstance = new mapboxgl.Map({
        container: ref.current,
        ...mapConfig,
      });

      mapInstance?.on("load", () => {
        mapLogger.log("Map loaded");
        setMap(mapInstance);
      });
    }
    //MJB removed map from dependency array because it set an endless loop
  }, [ref, mapConfig]); //eslint-disable-line

  /**
   * Function responsible for adding sources and layers to the map
   * There are a number of checks in place to ensure that sources
   * only get added to the map once the map and sources are loaded and
   * to ensure that layers are only added to the map once the layers are
   * loaded and the associated sources are added to the map
   */
  const loadMapData = useCallback(() => {
    const shouldAddData =
      !!map && sources?.length > 0 && layers?.length > 0 && !dataAdded;

    if (shouldAddData) {
      sources.forEach((source) => {
        const { id, ...rest } = source;
        const sourceExists = !!map.getSource(id);

        if (!sourceExists) {
          map.addSource(id, rest);
        }
      });

      mapLogger.log("Sources added to map");

      layers
        .sort((a, b) => ((a.drawOrder || 0) > (b.drawOrder || 0) ? -1 : 1))
        .forEach((layer) => {
          const { lreProperties, ...rest } = layer;
          const layerExists = map.getLayer(layer.id);
          if (!layerExists) {
            map.addLayer(rest);
          }
        });

      //MJB if the default color is not default, this will repaint for the hardcoded default value
      updateLayerStyles({
        id: "locationTypes",
        layerId: "locations-circle",
        layerFieldName: "location_type",
        name: "Location Types",
        options: [],
        type: "multi-select",
        value: [],
        paint: {
          "circle-color": [
            "match",
            ["get", "location_type"],
            ["Groundwater"],
            "#1F77B4",
            ["Reservoir"],
            "#AEC7E8",
            ["Surface Water"],
            "#FF7F0E",
            ["Weather"],
            "#FFBB78",
            "white",
          ],
          "circle-stroke-width": 2,
          "circle-stroke-color": "black",
        },
      });

      mapLogger.log("Layers added to map");

      setDataAdded(true);
    }
  }, [dataAdded, layers, map, sources]); //eslint-disable-line

  const addMapEvents = useCallback(() => {
    const shouldAddClickEvent = !!map && layers?.length > 0 && dataAdded;
    if (shouldAddClickEvent && !eventsRegistered) {
      //MJB add event listener for all circle and symbol layers
      // pointer on mouseover
      const cursorPointerLayerIds = layers
        .filter(
          (layer) =>
            (["circle", "symbol"].includes(layer.type) ||
              layer.id.includes("pap")) &&
            !layer.lreProperties?.popup?.excludePopup
        )
        .map((layer) => layer.id);
      cursorPointerLayerIds.forEach((layerId) => {
        map.on("mouseenter", layerId, () => {
          map.getCanvas().style.cursor = "pointer";

          map.on("mouseleave", layerId, () => {
            map.getCanvas().style.cursor = "";
          });
        });
      });

      const papLayers = layers
        .filter((layer) => layer.id.includes("pap"))
        .map((layer) => layer.id);

      map.on("moveend", (e) => {
        const papFeatures = map.queryRenderedFeatures({ layers: papLayers });
        if (papFeatures) {
          const uniqueFeatures = getUniqueFeatures(papFeatures, "ID");
          setCurrentPapFeatures(uniqueFeatures);
        }
      });

      map.on("click", (e) => {
        const features = map.queryRenderedFeatures(e.point);

        //Ensure that if the map is zoomed out such that multiple
        //copies of the feature are visible, the popup appears
        //over the copy being pointed to.
        //only for features with the properties.lat/long field (clearwater wells)
        const coordinates = features[0]?.properties?.latitude_dd
          ? [
              features[0].properties.longitude_dd,
              features[0].properties.latitude_dd,
            ]
          : [e.lngLat.lng, e.lngLat.lat];

        //MJB add check for popups so they only appear on our dynamic layers
        const popupLayerIds = layers
          .filter((layer) => !layer?.lreProperties?.popup?.excludePopup)
          .map((layer) => layer.id);
        if (
          features.length > 0 &&
          popupLayerIds.includes(features[0].layer.id)
        ) {
          if (
            features[0]?.layer?.id === "locations-circle" &&
            modeRef.current === "graph-mode"
          ) {
            setSelectedMonitoringLocation(features[0]?.properties);
            setResultsPanelVisible(true);
          }

          const myFeatures = features.filter((feature) =>
            popupLayerIds.includes(feature?.layer?.id)
          );
          // create popup node
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup layers={layers} features={myFeatures} />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
      });

      setEventsRegistered(true);
      mapLogger.log("Event handlers attached to map");
    }
  }, [map, layers, dataAdded, eventsRegistered, theme.currentTheme]); //eslint-disable-line

  /**
   * Handler used to apply user's filter values to the map instance
   * We rely on the `setFilter` method available on the map instance
   * and Mapbox expressions to apply filters to the wells layer
   * This function translates the filter values into valid
   * Mapbox expressions
   * Mapbox expressions are gnarly, powerful black box.
   * See https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
   * The easiest way to get a feel for expressions is to create a new map
   * in Mapbox Studio, add a map layer, apply some filters to the layer and
   * then click on the code icon in the sidebar drawer to inspect the
   * Mapbox expression that is generated for the applied filters
   * (ask Ben Tyler if this doesn't make sense)
   * @param {object} filterValues object representing all of the current
   * filter values
   */
  const updateLayerFilters = (filterValues) => {
    if (!!map) {
      /**
       * Setting to all means that all conditions must be met
       * Equivalent to and in SQL, change to "any" for the or
       * equivalent
       */
      const mapFilterExpression = ["all"];
      Object.values(filterValues).forEach((filter) => {
        if (filter.type === "multi-select") {
          mapFilterExpression.push([
            "match",
            ["get", filter.layerFieldName],
            [...filter.value].length ? [...filter.value] : "",
            true,
            false,
          ]);
        } else if (filter.type === "multi-select-array") {
          const mapFilterExpressionTemp = ["case"];
          if (filter?.value?.length) {
            filter.value.map((item) =>
              mapFilterExpressionTemp.push(
                ["in", item, ["get", filter.layerFieldName]],
                true
              )
            );
          } else {
            mapFilterExpressionTemp.push(
              ["in", "", ["get", filter.layerFieldName]],
              true
            );
          }
          mapFilterExpressionTemp.push(false);
          mapFilterExpression.push(mapFilterExpressionTemp);
        } else if (filter.type === "boolean") {
          //MJB only apply filter if toggle is true
          //MJB no filter applied if toggle is false
          if (filter.value) {
            mapFilterExpression.push([
              "==",
              ["get", filter.layerFieldName],
              filter.value,
            ]);
          }
        } else if (filter.type === "simple-number") {
          mapFilterExpression.push([
            ">=",
            ["get", filter.layerFieldName],
            filter.value,
          ]);
        }
      });

      //This is a quick fix for the map sometimes not updating the filters
      //on first load. The filters update in a useEffect in useFilters, but it
      // is possible that the styles are not fully loaded even though the layers,
      // sources, and events are added.

      if (!firstLoadRef.current) {
        const checkStyleLoaded = setInterval(() => {
          const styleLoaded = map?.isStyleLoaded();
          if (styleLoaded) {
            clearInterval(checkStyleLoaded);
            map.setFilter("locations-circle", mapFilterExpression);
            map.setFilter("locations-symbol", mapFilterExpression);
            firstLoadRef.current = true;
          }
        }, 200);
      } else {
        map.setFilter("locations-circle", mapFilterExpression);
        map.setFilter("locations-symbol", mapFilterExpression);
      }

      mapLogger.log("Filters updated on the locations layers");
    }
  };

  /**
   * Handler used to update paint styles applied to map layer
   * This is used to support the color wells by x control
   * We look through each provided paint style property and apply the
   * style rule to the layer
   * Reference https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setpaintproperty
   * @param {object} layer object representing a map layer
   */
  const updateLayerStyles = (layer) => {
    if (!!map) {
      Object.entries(layer.paint).forEach(([ruleName, ruleValue]) => {
        map.setPaintProperty(layer.layerId, ruleName, ruleValue);
      });
      setLayers((prevState) => {
        return prevState.map((d) => {
          if (d.id !== layer.layerId) return d;
          return {
            ...d,
            paint: {
              ...d.paint,
              ...layer.paint,
            },
          };
        });
      });
      mapLogger.log("Paint styles updated on the locations-circle layer");
    }
  };

  /**
   * Handler used to update the visibility property on a layer
   * We employ special logic in this handler to allow for toggling
   * the visibility of grouped layers on and off
   * This allows us to display a single item in the layers list
   * but to control the visibility of multiple map layers at once
   * A common use case would be for something like parcels where
   * you want to display a layer for the parcel outlines and a layer
   * for the parcel fill.
   * This approach allows us to only show one layer in
   * the layer control list but to turn both layers on/off
   * @param {string | number} options.id ID associated with the layer or layer group
   * @param {boolean} options.boolean whether the layer is on/off
   */
  const updateLayerVisibility = ({ id, visible }) => {
    /**
     * Get a list of the IDs for the layers that need to have their
     * visibility updated
     * The ID that is passed to the handler will either be the ID for
     * the layer or an ID for a layer group
     */
    const groupedLayerIds = layers
      ?.filter((layer) => {
        const key = layer?.lreProperties?.layerGroup || layer.id;
        return key === id;
      })
      .map(({ id }) => id);

    if (!!map) {
      /**
       * Loop through all of the layers and update the visibility
       * for all of the layers associated with the layer toggled
       * in the layer control
       */
      const updatedLayers = layers.map((layer) => {
        if (!!map.getLayer(layer.id) && groupedLayerIds.includes(layer.id)) {
          const visibleValue = visible ? "visible" : "none";
          map.setLayoutProperty(layer.id, "visibility", visibleValue);
          return {
            ...layer,
            layout: {
              ...layer?.layout,
              visibility: visibleValue,
            },
          };
        }
        return layer;
      });
      setLayers(updatedLayers);
      mapLogger.log(
        `Visibility set to ${visible ? "visible" : "none"} for the ${id} layer`
      );
    }
  };

  const updateLayerOpacity = useCallback(
    ({ id, opacity }) => {
      if (map) {
        const updatedLayers = layers.map((layer) => {
          if (map.getLayer(id) && layer.id === id) {
            map.setPaintProperty(id, "fill-opacity", opacity);
            return {
              ...layer,
              paint: {
                ...layer.paint,
                "fill-opacity": opacity,
              },
            };
          }
          return layer;
        });
        setLayers(updatedLayers);
        mapLogger.log(`Opacity set to ${opacity} for the ${id} layer`);
      }
    },
    [map, layers, setLayers]
  );

  const updateBasemap = (style) => {
    map?.setStyle(style.url);

    /**
     * After the map style changes we need to poll it every
     * 100 ms to determine if the new map style has loaded
     * After it is loaded, we add any existing sources and layers
     * back to the map
     */
    const checkStyleLoaded = setInterval(() => {
      const styleLoaded = map?.isStyleLoaded();
      if (styleLoaded) {
        clearInterval(checkStyleLoaded);
        setActiveBasemap(style.style);
        setDataAdded(false);
        loadMapData();
      }
    }, 1000);
  };

  // initialize and render the map
  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  // add all the sources and layers to the map
  useEffect(() => {
    loadMapData();
  }, [loadMapData]);

  // wire up all map related events
  useEffect(() => {
    addMapEvents();
  }, [addMapEvents]);

  return {
    activeBasemap,
    basemaps: BASEMAP_STYLES,
    layers,
    map,
    sources,
    updateBasemap,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    selectedMonitoringLocation,
    setSelectedMonitoringLocation,
    dataVizGraphType,
    setDataVizGraphType,
    eventsRegistered,
    currentPapFeatures,
    updateLayerOpacity,
  };
};

export { useMap };
