import { ButtonGroup, Button } from "@material-ui/core";

const WellStylesControl = ({ onChange, options, value, mode }) => {
  return (
    <ButtonGroup size="medium">
      {options
        .filter(
          (o) =>
            (mode === "map-explorer" && o.value !== "wqData") ||
            mode === "graph-mode"
        )
        .map((option) => {
          const active = value === option.value;
          return (
            <Button
              key={option.value}
              color={active ? "primary" : "inherit"}
              onClick={() => onChange(option.value)}
              variant={active ? "contained" : "outlined"}
            >
              {option.display}
            </Button>
          );
        })}
    </ButtonGroup>
  );
};

export default WellStylesControl;
