import React from "react";
import styled from "styled-components/macro";
import { titleize } from "inflected";

const PopupWrap = styled.div`
  height: 125px;
  z-index: 4;
  overflow-y: scroll;
  // width: 250px;
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const Popup = ({ feature }) => {
  const popupData = Object.entries(feature?.properties);

  return (
    <>
      <h2>{feature?.properties?.description}</h2>
      <PopupWrap>
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value]) => {
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>{titleize(key)}</strong>
                  </PopupCell>
                  <PopupCell>
                    <strong>{value}</strong>
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
    </>
  );
};

export default Popup;
