import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { add } from "date-fns";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  darken,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Link,
  Tooltip,
  Typography as MuiTypography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  dateFormatter,
  lastOfCurrentMonth,
  groupByValueArray,
  lineColors,
  firstOfYear,
} from "../../../utils";

import Panel from "../../../components/panels/Panel";
import Map from "../../../components/map/Map";
import TimeseriesFilters from "../../../components/filters/TimeseriesFilters";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import Table from "../../../components/Table";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";

import axios from "axios";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 92px);
  width: 100%;
`;
const FiltersContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const MapContainer = styled.div`
  height: 300px;
  width: 100%;
`;
const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;
const Chip = styled(MuiChip)`
  ${spacing};
  height: 30px;
  margin-top: 8px;
  font-size: 20px;
  padding: 5px 0;
  font-weight: 100;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const PrecipDailyAndCumulative = () => {
  const saveRef = useRef(null);

  //date filter defaults
  const defaultFilterValues = {
    previousDays: "",
    startDate: firstOfYear,
    endDate: lastOfCurrentMonth,
    checked: false,
  };
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  //columns and fields to render on table
  const tableColumns = [
    {
      title: "Location",
      field: "location_name",
      width: "100%",
    },
    {
      title: "Location Id",
      field: "locationid",
    },
    { title: "Parameter", field: "parameter", width: "100%" },
    { title: "Parameter Id", field: "parameterid", width: "100%" },
    {
      title: "Report Date",
      field: "rdate",
      render: (rowData) => {
        return dateFormatter(rowData.rdate, "MM/DD/YYYY");
      },
    },
    { title: "Daily Precipitation", field: "daily_precip" },
    { title: "Units", field: "result_unit_name" },
    { title: "Total 7 Day", field: "total_7day" },
    { title: "Total 30 Day", field: "total_30day" },
    { title: "Total YTD", field: "total_ytd" },
  ];

  const [graphData, setGraphData] = useState([]);
  const { isFetching, error, data } = useQuery(
    ["ts-precip-daily-cumulative"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-precip-daily-cumulative/`
        );

        const groupedDataArray = groupByValueArray(data, "locationid");
        const groupedDataObj = {};
        groupedDataArray.forEach(
          (item) => (groupedDataObj[item[0].locationid] = item)
        );
        setGraphData(groupedDataObj);

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState([]);
  //filtered data for graph, it filters selected locations
  //it keeps every date so the graph can still be panned and zoomed
  //it also mutates the data to be consumed by chartsJS
  //filtered data for table. if filters selected locations
  //it also filters by date
  const [filteredTableData, setFilteredTableData] = useState([]);
  useEffect(() => {
    if (data && Object.keys(graphData).length) {
      const tableFilterData =
        //if there is no value in the input and the prev is selected, yield every record
        filterValues.checked && filterValues.previousDays === ""
          ? data
          : //if the toggle is checked and it is not blank, yield x days
          filterValues.checked && filterValues.previousDays !== ""
          ? data.filter(
              (item) =>
                new Date(item.rdate) >=
                  add(new Date().getTime(), {
                    days: -filterValues.previousDays,
                  }) && new Date(item.rdate) <= new Date()
            )
          : //if the toggle is unchecked, yield date range
            data.filter(
              (item) =>
                new Date(item.rdate) >= filterValues.startDate &&
                new Date(item.rdate) <= filterValues.endDate
            );
      setFilteredTableData(tableFilterData);

      //mutate data for chartJS to use
      const defaultStyle = {
        pointRadius: 0,
        pointHoverRadius: 0,
      };
      const mutatedGraphData = {
        labels: graphData[155]?.map((item) => item.rdate),
        datasets: [
          {
            type: "line",
            units: graphData[156][0]?.result_unit_name,
            label: `Year-to-Date (${graphData[156][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: lighten(lineColors.lightBlue, 0.15),
            backgroundColor: lighten(lineColors.lightBlue, 0.15),
            data: graphData[156]?.map((item) => item.total_ytd),
            borderWidth: 4,
            borderCapStyle: "round",
            borderDash: [0, 10],
            spanGaps: true,
            hidden: false,
            ...defaultStyle,
            pointStyle: "circle",
          },
          {
            type: "line",
            units: graphData[155][0]?.result_unit_name,
            label: `Year-to-Date (${graphData[155][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: lighten(lineColors.lightGreen, 0.15),
            backgroundColor: lighten(lineColors.lightGreen, 0.15),
            data: graphData[155]?.map((item) => item.total_ytd),
            borderWidth: 4,
            borderCapStyle: "round",
            borderDash: [0, 10],
            spanGaps: true,
            hidden: false,
            ...defaultStyle,
            pointStyle: "circle",
          },
          {
            type: "line",
            units: graphData[156][0]?.result_unit_name,
            label: `30-Day Total (${graphData[156][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: darken(lineColors.lightBlue, 0.3),
            backgroundColor: darken(lineColors.lightBlue, 0.3),
            data: graphData[156]?.map((item) => item.total_30day),
            borderWidth: 3,
            borderDash: [10, 15],
            borderDashOffset: 8,
            spanGaps: true,
            hidden: true,
            ...defaultStyle,
            pointStyle: "dash",
          },
          {
            type: "line",
            units: graphData[155][0]?.result_unit_name,
            label: `30-Day Total (${graphData[155][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: darken(lineColors.lightGreen, 0.3),
            backgroundColor: darken(lineColors.lightGreen, 0.3),
            data: graphData[155]?.map((item) => item.total_30day),
            borderWidth: 3,
            borderDash: [10, 15],
            borderDashOffset: 8,
            spanGaps: true,
            hidden: true,
            ...defaultStyle,
            pointStyle: "dash",
          },
          {
            type: "line",
            units: graphData[156][0]?.result_unit_name,
            label: `7-Day Total (${graphData[156][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: darken(lineColors.lightBlue, 0.15),
            backgroundColor: darken(lineColors.lightBlue, 0.15),
            data: graphData[156]?.map((item) => item.total_7day),
            borderWidth: 4,
            spanGaps: true,
            hidden: true,
            ...defaultStyle,
            pointStyle: "line",
          },
          {
            type: "line",
            units: graphData[155][0]?.result_unit_name,
            label: `7-Day Total (${graphData[155][0]?.location_name})`,
            yAxisID: "yR",
            borderColor: darken(lineColors.lightGreen, 0.15),
            backgroundColor: darken(lineColors.lightGreen, 0.15),
            data: graphData[155]?.map((item) => item.total_7day),
            borderWidth: 4,
            spanGaps: true,
            hidden: true,
            ...defaultStyle,
            pointStyle: "line",
          },
          {
            type: "bar",
            units: graphData[156][0]?.result_unit_name,
            label: `Daily Precipitation (${graphData[156][0]?.location_name})`,
            yAxisID: "yL",
            borderColor: lineColors.lightBlue,
            backgroundColor: lineColors.lightBlue,
            data: graphData[156]?.map((item) => item.daily_precip),
            barPercentage: 0.95,
            categoryPercentage: 0.95,
            ...defaultStyle,
            pointStyle: "rect",
          },
          {
            type: "bar",
            units: graphData[155][0]?.result_unit_name,
            label: `Daily Precipitation (${graphData[155][0]?.location_name})`,
            yAxisID: "yL",
            borderColor: lineColors.lightGreen,
            backgroundColor: lineColors.lightGreen,
            data: graphData[155]?.map((item) => item.daily_precip),
            barPercentage: 0.95,
            categoryPercentage: 0.95,
            ...defaultStyle,
            pointStyle: "rect",
          },
        ],
      };
      setFilteredMutatedGraphData(mutatedGraphData);
    }
  }, [data, graphData, filterValues]); //eslint-disable-line

  const [noaaTotalDailyPrecip, setNoaaTotalDailyPrecip] = useState();
  const [mhfdTotalDailyPrecip, setMhfdTotalDailyPrecip] = useState();

  useEffect(() => {
    if (filteredTableData.length) {
      const groupedDataArray = groupByValueArray(
        filteredTableData,
        "locationid"
      );
      const groupedDataObj = {};
      groupedDataArray.forEach(
        (item) => (groupedDataObj[item[0].locationid] = item)
      );

      let sumNoaaDailyPrecip = groupedDataObj[155]
        .map((item) => item.daily_precip)
        .reduce((prev, next) => prev + next, 0);
      sumNoaaDailyPrecip = sumNoaaDailyPrecip.toFixed(3);
      setNoaaTotalDailyPrecip(sumNoaaDailyPrecip);

      let sumMhfdDailyPrecip = groupedDataObj[156]
        .map((item) => item.daily_precip)
        .reduce((prev, next) => prev + next, 0);
      sumMhfdDailyPrecip = sumMhfdDailyPrecip.toFixed(3);
      setMhfdTotalDailyPrecip(sumMhfdDailyPrecip);
    }
  }, [filteredTableData]);

  return (
    <>
      <Helmet title="Daily Precipitation vs Cumulative Precipitation" />
      <Typography variant="h3" gutterBottom display="inline">
        Daily Precipitation vs Cumulative Precipitation
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Daily Precipitation vs Cumulative Precipitation</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={7}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <Map locationsToInclude={["MHFD 10091", "NCDC USW00093067"]} />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="date-filters"
              id="date-filters"
            >
              <Typography variant="h4" ml={2}>
                Date Filters{" "}
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <FiltersContainer>
                  <TimeseriesFilters
                    filterValues={filterValues}
                    changeFilterValues={changeFilterValues}
                  />
                </FiltersContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Graph
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TimeseriesContainer>
                  <Grid
                    container
                    pb={6}
                    mt={2}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      style={{ flexGrow: 1, maxWidth: "calc(100% - 54px)" }}
                    />
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "6px",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        width: "53px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Daily Precipitation vs Cumulative Precipitation Timeseries Graph"
                      />
                    </Grid>
                  </Grid>
                  <TableWrapper>
                    <TimeseriesLineChart
                      data={filteredMutatedGraphData}
                      error={error}
                      isLoading={isFetching}
                      filterValues={filterValues}
                      yLLabel="Daily Precipitation (inches)"
                      yRLLabel="Cumulative Precipitation (inches)"
                      ref={saveRef}
                      tooltipFormat="MM-DD-YYYY"
                      stacked={false}
                      xLabelFormat="MM-DD-YYYY"
                      // reverseLegend={false}
                      // interactionMode="nearest"
                    />
                  </TableWrapper>
                </TimeseriesContainer>
                {Object.keys(graphData).length ? (
                  <>
                    <Typography variant="h4" mt={2}>
                      {filterValues.checked && filterValues.previousDays === ""
                        ? "Total precipitation for every record on file:"
                        : filterValues.checked &&
                          filterValues.previousDays !== ""
                        ? `Total precipitation for the last ${filterValues.previousDays} days:`
                        : `Total precipitation between ${filterValues.startDate.toDateString()} and ${filterValues.endDate.toDateString()}:`}
                    </Typography>
                    <div>
                      <Tooltip title="NOAA KAPA" placement="bottom">
                        <Chip
                          mr={5}
                          color="secondary"
                          label={`${noaaTotalDailyPrecip} (inches)`}
                        />
                      </Tooltip>
                      <Tooltip title="MHFD Cherry Creek" placement="bottom">
                        <Chip
                          color="primary"
                          label={`${mhfdTotalDailyPrecip} (inches)`}
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : null}
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    // isLoading={isLoading}
                    label="Daily Precipitation vs Cumulative Precipitation Timeseries Table"
                    columns={tableColumns}
                    data={filteredTableData}
                    height="600px"
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default PrecipDailyAndCumulative;
