import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, MultiSelect } from "@lrewater/lre-react";
import {
  dateFormatter,
  extractDate,
  groupByValueArray,
  lineColors,
} from "../../../utils";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  isWidthUp,
  lighten,
  TextField,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import { customSecondary } from "../../../theme/variants";
import { Alert, Autocomplete } from "@material-ui/lab";
import TimeseriesComparisonMap from "../../../components/map/TimeseriesComparisonMap";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import TimeseriesCompareLineChart from "../../../components/graphs/TimeseriesCompareLineChart";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const SidebarSection = styled(MuiTypography)`
  ${spacing}
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapContainer = styled.div`
  height: 584px;
  width: 100%;
`;

const TimeSeriesComparison = ({ width }) => {
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    parameterGroupsLeft: [147],
    parameterGroupsRight: [152],
    parameterLeft: "",
    locationsLeft: [],
    parameterRight: "",
    locationsRight: [],
    startDate: extractDate("10/1/2021"),
    endDate: extractDate(new Date()),
  });

  const { data: ParameterGroups } = useQuery(
    ["/api/dropdown-parameter-group"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dropdown-parameter-group`
        );

        //logic to select every param group after fetch
        // setFilterValues((prevState) => {
        //   let newValues = { ...prevState };
        //   const selectAllParameterGroups = data.map(
        //     (parameterGroup) => parameterGroup.param_groupid
        //   );
        //   newValues["parameterGroupsLeft"] = selectAllParameterGroups;
        //   newValues["parameterGroupsRight"] = selectAllParameterGroups;
        //   return newValues;
        // });

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Parameters } = useQuery(
    ["dropdown-parameters"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dropdown-parameters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Locations } = useQuery(
    ["ts-compare-dropdown-locations-assoc"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dropdown-locations-assoc`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const filterLocationsByParameter = (locations, parameter) => {
    return locations.filter((location) =>
      location.parameterid_array.includes(parameter)
    );
  };

  const filterLocationsByParameterGroups = (locations, parameterGroups) => {
    return locations.filter(
      (location) =>
        parameterGroups.filter((parameterGroup) =>
          location.param_groupid_array.includes(parameterGroup)
        ).length
    );
  };

  const filterLocationsByParameterGroups2 = (locations, parameterGroups) => {
    return locations.filter((location) =>
      parameterGroups.includes(location.param_groupid)
    );
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      if (name === "parameterLeft") {
        newValues["locationsLeft"] = [];
      }
      if (name === "parameterRight") {
        newValues["locationsRight"] = [];
      }

      if (name === "parameterGroupsLeft") {
        newValues["locationsLeft"] = [];
        newValues["parameterLeft"] = "";
      }

      if (name === "parameterGroupsRight") {
        newValues["locationsRight"] = [];
        newValues["parameterRight"] = "";
      }

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    ["ts-compare-daily-data"],
    async () => {
      try {
        const { data: dataLeftAxis } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-compare-daily-data/${
            filterValues.parameterLeft
          }/${filterValues.locationsLeft.map(
            (location) => location.locationid
          )}/${filterValues.startDate}/${filterValues.endDate}`
        );

        const { data: dataRightAxis } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-compare-daily-data/${
            filterValues.parameterRight
          }/${filterValues.locationsRight.map(
            (location) => location.locationid
          )}/${filterValues.startDate}/${filterValues.endDate}`
        );

        const groupedData = {
          leftAxis: groupByValueArray(dataLeftAxis, "locationid"),
          rightAxis: groupByValueArray(dataRightAxis, "locationid"),
        };

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const tableColumns = [
    { title: "Parameter Group", field: "param_group" },
    { title: "Parameter Name", field: "param_descrip" },
    { title: "Location Name", field: "location_descrip" },
    {
      title: "Result",
      field: "result_value",
      render: (rowData) => {
        return rowData.result_value.toFixed(2);
      },
    },
    { title: "Units", field: "result_unit" },
    {
      title: "Last Report",
      field: "rdate",
      render: (rowData) => {
        return dateFormatter(rowData.rdate, "MM/DD/YYYY, h:mm A");
      },
    },
    { title: "Data Provider Name", field: "source_desc" },
    { title: "Location Type Name", field: "location_type" },
    { title: "Taxa", field: "taxa" },
    { title: "Depth", field: "depth_value" },
    { title: "Depth Units", field: "depth_unitid" },
    { title: "Depth Category", field: "depthcat_display" },
    { title: "Flag", field: "TEXT" },
    { title: "Flow Conditions", field: "flow_conditions_display" },
  ];

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data) {
      const noShowDotsFor = [2, 1901, 1906, 1907, 1908];
      let count = -1;
      const graphData = {
        yLLabel: data?.leftAxis?.length
          ? `${data?.leftAxis[0][0]?.param_descrip} (${data?.leftAxis[0][0]?.result_unit})`
          : null,
        yRLabel: data?.rightAxis?.length
          ? `${data?.rightAxis[0][0]?.param_descrip} (${data?.rightAxis[0][0]?.result_unit})`
          : null,
        datasets: [
          ...data.rightAxis.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.rdate,
                  y: item.result_value,
                };
              }),
              yAxisID: "yR",
              units: location[0].result_unit,
              pointStyle: "circle",
              borderWidth: 2,
              borderCapStyle: "round",
              borderDash: [8, 10],
              pointRadius: noShowDotsFor.includes(
                data?.rightAxis[0][0]?.parameterid
              )
                ? 0
                : 2,
              pointHoverRadius: 4,
              label: `${location[0].location_descrip} (right axis / ${location[0].result_unit})`,
              borderColor: Object.values(lineColors)[count],
              backgroundColor: lighten(Object.values(lineColors)[count], 0.5),
              tension: 0.5,
            };
          }),
          ...data.leftAxis.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.rdate,
                  y: item.result_value,
                };
              }),
              yAxisID: "yL",
              units: location[0].result_unit,
              pointStyle: "circle",
              fill: false,
              borderWidth: 2,
              pointRadius: noShowDotsFor.includes(
                data?.leftAxis[0][0]?.parameterid
              )
                ? 0
                : 2,
              pointHoverRadius: 4,
              label: `${location[0].location_descrip} (left axis / ${location[0].result_unit})`,
              borderColor: Object.values(lineColors)[count],
              backgroundColor: lighten(Object.values(lineColors)[count], 0.5),
              tension: 0.5,
            };
          }),
        ],
      };
      setGraphData(graphData);
    }
  }, [data]);

  return (
    <>
      <Helmet title="Time Series Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Time Series Comparisons
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Time Series Comparisons</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        {Locations && ParameterGroups && (
          <Grid item xs={12} md={12} lg={12} xl={5}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4" ml={2}>
                  Map
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MapContainer>
                  <TimeseriesComparisonMap
                    locations={Locations.map((location) => location.locationid)}
                    selectedLeftLocations={filterValues.locationsLeft.map(
                      (location) => location.locationid
                    )}
                    selectedRightLocations={filterValues.locationsRight.map(
                      (location) => location.locationid
                    )}
                  />
                </MapContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {Parameters && Locations && ParameterGroups && (
          <>
            <Grid item xs={12} md={12} lg={12} xl={7}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Filter Controls
                  </Typography>
                </AccordionSummary>
                <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                  <AccordionDetails>
                    <FiltersContainer
                      height={isWidthUp("xl", width) ? "584px" : "100%"}
                      style={{ display: "flex" }}
                    >
                      <Grid container pb={6} mt={2}>
                        <Grid item xs={12}>
                          <SidebarSection>Date Filters</SidebarSection>
                          <DatePicker
                            size="small"
                            name="startDate"
                            label="Start Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.startDate}
                            onChange={handleFilter}
                            width={150}
                          />
                          <DatePicker
                            size="small"
                            name="endDate"
                            label="End Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.endDate}
                            onChange={handleFilter}
                            width={150}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <SidebarSection>Left Axis</SidebarSection>
                          <MultiSelect
                            size="small"
                            name="parameterGroupsLeft"
                            label="Parameter Groups"
                            variant="outlined"
                            valueField="param_groupid"
                            displayField="param_group"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            data={ParameterGroups}
                            value={filterValues.parameterGroupsLeft}
                            onChange={handleFilter}
                            width="100%"
                          />
                          <Autocomplete
                            size="small"
                            id="parameterLeft"
                            name="parameterLeft"
                            options={filterLocationsByParameterGroups2(
                              Parameters,
                              filterValues.parameterGroupsLeft
                            )}
                            getOptionLabel={(option) =>
                              option.param_descrip ?? ""
                            }
                            onChange={(e, value) => {
                              handleFilter({
                                target: {
                                  name: "parameterLeft",
                                  value: value?.parameterid,
                                },
                              });
                            }}
                            value={
                              Parameters.find(
                                (parameter) =>
                                  parameter?.parameterid ===
                                  filterValues.parameterLeft
                              ) ?? null
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  style={{ margin: "4px" }}
                                  margin="normal"
                                  variant="outlined"
                                  label="Parameters"
                                />
                              );
                            }}
                          />
                          <Autocomplete
                            size="small"
                            id="locationsLeft"
                            name="locationsLeft"
                            options={filterLocationsByParameter(
                              filterLocationsByParameterGroups(
                                Locations,
                                filterValues.parameterGroupsLeft
                              ),
                              filterValues.parameterLeft
                            )}
                            getOptionLabel={(option) =>
                              option.location_descrip ?? ""
                            }
                            multiple
                            limitTags={3}
                            disableCloseOnSelect
                            filterSelectedOptions
                            onChange={(e, value) => {
                              handleFilter({
                                target: {
                                  name: "locationsLeft",
                                  value: value,
                                },
                              });
                            }}
                            value={filterValues.locationsLeft}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  style={{ margin: "4px" }}
                                  margin="normal"
                                  variant="outlined"
                                  label="Locations"
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SidebarSection>Right Axis</SidebarSection>
                          <MultiSelect
                            size="small"
                            name="parameterGroupsRight"
                            label="Parameter Groups"
                            variant="outlined"
                            valueField="param_groupid"
                            displayField="param_group"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            data={ParameterGroups}
                            value={filterValues.parameterGroupsRight}
                            onChange={handleFilter}
                            width="100%"
                          />
                          <Autocomplete
                            size="small"
                            id="parameterRight"
                            name="parameterRight"
                            options={filterLocationsByParameterGroups2(
                              Parameters,
                              filterValues.parameterGroupsRight
                            )}
                            getOptionLabel={(option) =>
                              option.param_descrip ?? ""
                            }
                            onChange={(e, value) => {
                              handleFilter({
                                target: {
                                  name: "parameterRight",
                                  value: value?.parameterid,
                                },
                              });
                            }}
                            value={
                              Parameters.find(
                                (parameter) =>
                                  parameter?.parameterid ===
                                  filterValues.parameterRight
                              ) ?? null
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  style={{ margin: "4px" }}
                                  margin="normal"
                                  variant="outlined"
                                  label="Parameters"
                                />
                              );
                            }}
                          />
                          <Autocomplete
                            size="small"
                            id="locationsRight"
                            name="locationsRight"
                            options={filterLocationsByParameter(
                              filterLocationsByParameterGroups(
                                Locations,
                                filterValues.parameterGroupsRight
                              ),
                              filterValues.parameterRight
                            )}
                            getOptionLabel={(option) =>
                              option.location_descrip ?? ""
                            }
                            multiple
                            limitTags={3}
                            disableCloseOnSelect
                            filterSelectedOptions
                            onChange={(e, value) => {
                              handleFilter({
                                target: {
                                  name: "locationsRight",
                                  value: value,
                                },
                              });
                            }}
                            value={filterValues.locationsRight}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  style={{ margin: "4px" }}
                                  margin="normal"
                                  variant="outlined"
                                  label="Locations"
                                />
                              );
                            }}
                          />
                        </Grid>
                        <SubmitGrid item container>
                          <Grid item style={{ width: "calc(100% - 162px)" }}>
                            {!data && (
                              <Alert severity="info">
                                After selecting your timeseries inputs, click
                                the 'Submit' button to load an interactive
                                timeseries plot for comparison across different
                                locations and parameters.
                              </Alert>
                            )}
                            {(data?.leftAxis?.length === 0 ||
                              data?.rightAxis?.length === 0) && (
                              <Alert severity="warning">
                                No data available for{" "}
                                {data?.leftAxis?.length === 0 &&
                                data?.rightAxis?.length === 0
                                  ? "left or right"
                                  : data?.leftAxis?.length === 0
                                  ? "left"
                                  : "right"}{" "}
                                axis
                              </Alert>
                            )}
                          </Grid>
                          <Grid item>
                            <SaveGraphButton
                              ref={saveRef}
                              title="Timeseries Comparison Graph"
                              disabled={isFetching || !data}
                            />
                            <Button
                              onClick={() => refetch()}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              style={{ marginLeft: "10px" }}
                              disabled={
                                !filterValues.locationsLeft.length ||
                                !filterValues.locationsRight.length
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                        </SubmitGrid>
                      </Grid>
                    </FiltersContainer>
                  </AccordionDetails>
                </Box>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {Parameters && Locations && ParameterGroups && (
        <>
          {data && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="time-series"
                    id="time-series"
                  >
                    <Typography variant="h4" ml={2}>
                      Graph
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TimeseriesContainer>
                        <TableWrapper>
                          <TimeseriesCompareLineChart
                            // yLReverse={filterValues.parameterLeft === 7}
                            // yRReverse={filterValues.parameterRight === 7}
                            data={graphData}
                            error={error}
                            isLoading={isFetching}
                            filterValues={filterValues}
                            locationsOptions={Locations}
                            yLLabel={graphData?.yLLabel}
                            yRLabel={graphData?.yRLabel}
                            xLabelUnit="week"
                            ref={saveRef}
                            tooltipFormat="MM-DD-YYYY"
                            footerLabel="Hours Pumped"
                          />
                        </TableWrapper>
                      </TimeseriesContainer>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {data && !isFetching && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                  >
                    <Typography variant="h4" ml={2}>
                      Table
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TableWrapper>
                        <Table
                          // isLoading={isLoading}
                          label="Time Series Compare Table"
                          columns={tableColumns}
                          data={[
                            ...[].concat.apply([], data?.leftAxis),
                            ...[].concat.apply([], data?.rightAxis),
                          ]}
                          height="590px"
                        />
                      </TableWrapper>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default withWidth()(TimeSeriesComparison);
