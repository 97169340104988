import React, { useRef } from "react";

import { useMap } from "./hooks/useMap";
import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MainControl from "./controls/mainControl/";

import MapIFramePAP from "./map/MapIFramePAP";

const PublicMapIFramePAP = () => {
  const defaultExpandedLayers = [
    "PAP Sites",
    "PAP Lines",
    "Cherry Creek Basin Boundary",
  ];

  const swapVisibility = [
    "pap-lines-line",
    "pap-lines-line2",
    "pap-lines-symbol",
    "pap-sites-circle",
    "pap-sites-symbol",
    "locations-circle",
    "locations-symbol",
    "wastewater-treatment-plants-circle",
    "wastewater-treatment-plants-symbol",
  ];
  const mapContainer = useRef(null);

  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    updateLayerVisibility,
    updateBasemap,
    eventsRegistered,
    currentPapFeatures,
    updateLayerOpacity,
  } = useMap({
    ref: mapContainer,
    mapConfig: INIT_MAP_CONFIG,
    swapVisibility: swapVisibility,
  });

  return (
    <>
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}

      <MapIFramePAP ref={mapContainer}>
        {eventsRegistered && (
          <MainControl
            map={map}
            features={currentPapFeatures}
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            layers={layers}
            onBasemapChange={updateBasemap}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            defaultExpanded="pap"
            defaultExpandedLayers={defaultExpandedLayers}
          />
        )}
      </MapIFramePAP>
    </>
  );
};

export default PublicMapIFramePAP;
