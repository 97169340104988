import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import { INIT_FILTER_VALUES } from "../../constants";
import { convertUTCDateToLocalDate } from "../../../../utils";

/**
 * Custom hook responsible for controlling the state related to the
 * Map filter controls
 * Accepts one argument, `onFilterChange` that is a callback that
 * can be run whenever a filter value changes
 */
const useFilters = ({ onFilterChange, mode, eventsRegistered }) => {
  const [filterValues, setFilterValues] = useState(INIT_FILTER_VALUES);

  /**
   * Hit the API and get a list of options for each filter
   * The endpoint returns an object where each key/value pair
   * represents the options for a specific filter
   * This approach allows us to make a single API request instead
   * of multiple
   */
  const { data: filtersData } = useQuery(
    ["filtersData"],
    async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`
        );
        return response;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleUpdateDefaultFiltersModeToggle = useCallback(() => {
    setFilterValues((prevState) => {
      const newState = { ...prevState };

      newState.parameterGroups = {
        ...newState.parameterGroups,
        value: filtersData?.data?.parameterGroups?.map(({ value }) => value),
      };

      newState.parameters = {
        ...newState.parameters,
        layerFieldName: `parameterid_array_alldata`,
        value: filtersData?.data?.parameters?.map(({ value }) => value),
      };

      newState.flow = {
        ...newState.flow,
        value: "alldata",
      };

      if (mode === "graph-mode") {
        newState.min_records = {
          ...newState.min_records,
          value: 0,
        };
      } else {
        newState.min_records = {
          ...newState.min_records,
          value: 1,
          layerFieldName: `recordcount_alldata`,
        };
      }

      // Notify about filter change
      onFilterChange(newState);
      return newState;
    });
  }, [filtersData, mode]); //eslint-disable-line

  /**
   * Set the options and initial value for each filter based on
   * what is returned from the DB
   */
  useEffect(() => {
    if (filtersData?.data && eventsRegistered) {
      const newFilterValues = {
        ...filterValues,
        locationTypes: {
          ...filterValues.locationTypes,
          options: filtersData?.data?.locationTypes || [],
          value:
            filtersData?.data?.locationTypes.map(({ value }) => value) || [],
        },
        parameterGroups: {
          ...filterValues.parameterGroups,
          options: filtersData?.data?.parameterGroups || [],
          value:
            filtersData?.data?.parameterGroups.map(({ value }) => value) || [],
        },
        parameters: {
          ...filterValues.parameters,
          options: filtersData?.data?.parameters || [],
          value: filtersData?.data?.parameters.map(({ value }) => value) || [],
        },
        analysis: {
          ...filterValues.analysis,
          options: filtersData?.data?.analysis || [],
          value: "median",
        },
        flow: {
          ...filterValues.flow,
          options: filtersData?.data?.flow || [],
          value: "alldata",
        },
        min_records: {
          ...filterValues.min_records,
          value: 0,
        },
        startDate: {
          ...filterValues.startDate,
          value:
            convertUTCDateToLocalDate(new Date(filtersData?.data?.startDate)) ||
            null,
        },
        endDate: {
          ...filterValues.endDate,
          value:
            convertUTCDateToLocalDate(new Date(filtersData?.data?.endDate)) ||
            null,
        },
      };
      onFilterChange(newFilterValues);
      setFilterValues(newFilterValues);
    }
  }, [filtersData?.data, eventsRegistered]); //eslint-disable-line

  const handleSelectAll = (name) => {
    setFilterValues((prevState) => {
      const newState = { ...prevState };

      if (name === "parameters") {
        // Only select parameters within selectedParameterGroups
        const selectedParameterGroups = filterValues?.parameterGroups?.value;

        if (selectedParameterGroups) {
          const selectedValues = filtersData?.data?.parameters
            ?.filter((parameter) =>
              selectedParameterGroups.includes(parameter.group)
            )
            .map(({ value }) => value);

          newState[name] = {
            ...newState[name],
            value: selectedValues,
          };
        }
      } else {
        newState[name] = {
          ...newState[name],
          value: filtersData?.data?.[name]?.map(({ value }) => value),
        };
      }
      onFilterChange(newState);
      return newState;
    });
  };

  const handleSelectNone = (name) => {
    setFilterValues((prevState) => {
      const newState = {
        ...prevState,
        [name]: {
          ...prevState[name],
          value: [],
        },
      };
      // also clears parameters when parameterGroups are cleared
      if (name === "parameterGroups" && mode === "graph-mode") {
        newState.parameters = {
          ...newState.parameters,
          value: [],
        };
      }
      onFilterChange(newState);
      return newState;
    });
  };

  const handleFilterValues = (event) => {
    const { checked, name, value } = event?.target || {};

    const type = filterValues[name]?.type;

    // todo figure out how to filter parameters based on selected parameter groups
    if (type === "multi-select" || type === "multi-select-array") {
      setFilterValues((prevState) => {
        const newValue = [...prevState[name].value];
        const existingIndex = newValue.indexOf(value);
        if (existingIndex > -1) {
          newValue.splice(existingIndex, 1);
        } else {
          newValue.push(value);
        }

        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: newValue,
          },
        };

        // also cleans parameters when a parameter group is unselected
        // only in graph mode, in explorer every parameter stays selected
        if (name === "parameterGroups" && mode === "graph-mode") {
          const selectedParameterGroups = newState.parameterGroups?.value;

          if (selectedParameterGroups) {
            const selectedValues = newState.parameters?.value?.filter(
              (parameterIndex) =>
                selectedParameterGroups.includes(
                  newState.parameters?.options?.find(
                    (parameter) => parameter.value === parameterIndex
                  )?.group
                )
            );

            newState.parameters = {
              ...newState.parameters,
              value: selectedValues,
            };
          }
        }

        onFilterChange(newState);
        return newState;
      });
    } else if (type === "boolean") {
      setFilterValues((prevState) => {
        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: checked,
          },
        };
        onFilterChange(newState);
        return newState;
      });
    } else {
      if (name === "flow") {
        setFilterValues((prevState) => {
          const newState = {
            ...prevState,
            [name]: {
              ...prevState[name],
              value: value,
            },
            min_records: {
              ...prevState.min_records,
              layerFieldName: `recordcount_${value}`,
            },
            parameters: {
              ...prevState.parameters,
              layerFieldName: `parameterid_array_${value}`,
            },
          };
          onFilterChange(newState);
          return newState;
        });
      } else {
        setFilterValues((prevState) => {
          const newState = {
            ...prevState,
            [name]: {
              ...prevState[name],
              value: type === "simple-number" ? +value : value,
            },
          };
          onFilterChange(newState);
          return newState;
        });
      }
    }
  };

  return {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    filtersData: filtersData?.data,
    handleUpdateDefaultFiltersModeToggle,
  };
};

export default useFilters;
