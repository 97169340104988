import React, { useState, useMemo } from "react";
import {
  Box,
  Card,
  Collapse,
  Icon,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography as MuiTypography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styled, { keyframes } from "styled-components";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import Loader from "../../../components/Loader";
import BenchmarkPopover from "./BenchmarkPopover";
import { makeStyles } from "@material-ui/core/styles";
import { convertUTCDateToLocalDate, dateFormatter } from "../../../utils";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Alert, AlertTitle } from "@material-ui/lab";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: 49px;
  bottom: 48px;
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: calc(100% - 49px - 49px);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const GraphTooltip = styled.div`
  background-color: white;
  padding: 10px;
  border: 1px solid rgb(204, 204, 204);
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 146px);
  width: 100%;
`;
const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;
const BenchmarkCircle = styled.div`
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  margin: auto;
  color: white;
  line-height: 45px;
  border: black solid 2px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

// const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const dateToInt = (data) => {
  return data.map((d) => {
    return {
      ...d,
      collect_date: convertUTCDateToLocalDate(
        new Date(d.collect_date)
      ).getTime(),
    };
  });
};

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      stroke="purple"
      strokeWidth={3}
      fill={payload.nondetect ? "transparent" : "purple"}
    />
  );
};

const useRowStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    borderRadius: 4,
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

const formatStatistic = (analysis, flow, row) => {
  const fieldName = `${flow}_${analysis === "median" ? "median" : "pctile"}`;
  return row[fieldName];
};

const formatValue = (analysis, flow, row, benchmarkScaleColors) => {
  const fieldName = `${flow}_bmk_${
    analysis === "median" ? "median" : "pctile"
  }`;

  const benchmarkValue = row[fieldName];
  const backgroundColor = benchmarkScaleColors.find(
    (d) => d.bmk_value === benchmarkValue
  )?.dot_symbol_color;

  if (!backgroundColor) return null;

  return <BenchmarkCircle backgroundColor={backgroundColor} />;
};

const setTrendIcon = (trend) => {
  const val = trend ? trend.toLowerCase() : "";
  if (val === "no trend") {
    return `swap_vert`;
  } else if (val.includes(`increasing`)) {
    return `arrow_upward`;
  } else if (val.includes(`decreasing`)) {
    return `arrow_downward`;
  } else if (val === "stable") {
    return `trending_flat`;
  } else {
    return "not_interested";
  }
};

const CustomDailyTooltip = React.memo(
  ({ payload, active }) => {
    if (active) {
      const item = payload[0]?.payload ?? null;
      return (
        <GraphTooltip>
          <Typography variant="h4">{`Site : ${item.location_name}`}</Typography>
          <Typography variant="body2">{`Date : ${dateFormatter(
            item.collect_date,
            "MM-DD-YYYY"
          )}`}</Typography>
          <Typography variant="body2">{`Value : ${item.result} ${item.units}`}</Typography>
        </GraphTooltip>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    const isActiveEqual = prevProps.active === nextProps.active;

    const isPayloadNdxEqual =
      prevProps.payload[0]?.payload.collect_date ===
      nextProps.payload[0]?.payload.collect_date;

    return isActiveEqual && isPayloadNdxEqual;
  }
);

const CustomAnnualTooltip = React.memo(
  ({ payload, active, flow }) => {
    if (active && payload?.length > 0) {
      const item = payload[0].payload; // Assuming payload structure is consistent
      return (
        <GraphTooltip>
          <Typography variant="h4">{`Site : ${item.location_name}`}</Typography>
          <Typography variant="body2">{`Year : ${item.collect_year}`}</Typography>
          <Typography variant="body2">{`Median : ${
            item[`result_median_${flow}`]
          } ${item.units}`}</Typography>
          <Typography variant="body2">{`85th Percentile : ${
            item[`result_pctile_${flow}`]
          } ${item.units}`}</Typography>
        </GraphTooltip>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    const isActiveEqual = prevProps.active === nextProps.active;

    const isCollectYearEqual =
      prevProps.payload[0]?.payload.collect_year ===
      nextProps.payload[0]?.payload.collect_year;

    const isFlowEqual = prevProps.flow === nextProps.flow;

    return isActiveEqual && isCollectYearEqual && isFlowEqual;
  }
);

const RenderTitle = React.memo(({ locationName, locationId, locationType }) => {
  const title = (
    <>
      <Typography variant="h4">
        <strong>{`${locationName} (${locationId})`}</strong>
      </Typography>
      <Typography variant="h4">{locationType}</Typography>
    </>
  );

  return (
    <Box flexDirection="column" display="flex">
      {title}
    </Box>
  );
});

function TimeSeriesGraphRow({
  row,
  data,
  benchmarkScaleColors,
  isTimeSeriesResultsLoading,
  filterValues,
}) {
  const {
    flow: { value: flow },
    analysis: { value: analysis },
  } = filterValues;

  const { lineData, barData } = useMemo(() => {
    const filterDataByFlow = (records) => {
      switch (flow) {
        case "alldata":
          return records;
        case "baseflow":
          return records.filter((record) => record.flow_conditions === 2);
        case "stormflow":
          return records.filter((record) => record.flow_conditions === 1);
        default:
          return [];
      }
    };

    return {
      lineData: filterDataByFlow(data?.line[row?.parameter_index] ?? []),
      barData: data?.bar[row?.parameter_index] ?? [],
    };
  }, [data, row?.parameter_index, flow]);

  const formattedStatistic = useMemo(
    () => formatStatistic(analysis, flow, row),
    [analysis, flow, row]
  );

  const formattedValue = useMemo(
    () => formatValue(analysis, flow, row, benchmarkScaleColors),
    [analysis, flow, row, benchmarkScaleColors]
  );

  const transformedLineData = useMemo(() => {
    return lineData?.length > 0 ? dateToInt(lineData) : [];
  }, [lineData]);

  const classes = useRowStyles();
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    typeof data !== "undefined" && (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row">
            {row.param_abbrev}
          </TableCell>
          <TableCell align="center">
            {formattedStatistic} {row.unit_name}
          </TableCell>
          <TableCell align="center">
            <div
              aria-owns={openPopover ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                cursor: isTimeSeriesResultsLoading ? "default" : "pointer",
              }}
            >
              {formattedValue}
            </div>
          </TableCell>
          <Popover
            disableAutoFocus
            disableEnforceFocus
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            // disableRestoreFocus
          >
            {!isTimeSeriesResultsLoading &&
              (lineData?.length > 0 || barData?.length > 0) && (
                <BenchmarkPopover data={lineData[0] ?? barData[0] ?? {}} />
              )}
          </Popover>
          <TableCell align="center">
            <Icon>{setTrendIcon(row[`${flow}_trend_dynamic`])}</Icon>
            <br />
            {row[`${flow}_trend_dynamic`] === "No Current Trend"
              ? "NA"
              : row[`${flow}_trend_dynamic`]}
          </TableCell>
          <TableCell align="center">{row[`${flow}_recordcount`]}</TableCell>
          <TableCell align="center">{`${dateFormatter(
            row[[`${flow}_por_start`]],
            "MM-DD-YYYY"
          )} - ${dateFormatter(
            row[`${flow}_por_end`],
            "MM-DD-YYYY"
          )}`}</TableCell>
          <TableCell align="center">{`${dateFormatter(
            row.fullpor_start,
            "MM-DD-YYYY"
          )} - ${dateFormatter(row.fullpor_end, "MM-DD-YYYY")}`}</TableCell>

          <TableCell align={"right"}>
            {isTimeSeriesResultsLoading ? (
              <Loader />
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        {!isTimeSeriesResultsLoading && (
          <TableRow style={{ backgroundColor: "#eee" }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Card style={{ margin: "12px" }}>
                  {lineData?.length > 0 ? (
                    <>
                      <Typography variant="h3" align="center" mt={2}>
                        Daily Time Series
                      </Typography>
                      {/*TODO figure out why using responsive container blows everything up*/}
                      <ResponsiveContainer height={250}>
                        <LineChart
                          margin={{
                            top: 25,
                            right: 75,
                            bottom: 25,
                            left: 75,
                          }}
                          data={transformedLineData}
                        >
                          <RechartsTooltip content={<CustomDailyTooltip />} />
                          <ReferenceArea
                            y1={0}
                            fill={lineData[0]?.bmk_color0 || "#EEEEEE"}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={lineData[0]?.bmk_line0}
                            fill={lineData[0]?.bmk_color1}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={lineData[0]?.bmk_line1}
                            fill={lineData[0]?.bmk_color2}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={lineData[0]?.bmk_line2}
                            fill={lineData[0]?.bmk_color3}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={lineData[0]?.bmk_line3}
                            fill={lineData[0]?.bmk_color4}
                            fillOpacity={1}
                          />
                          <CartesianGrid strokeDasharray="1 6" stroke="white" />
                          this line is just responsible for adding info to the
                          tooltip without having to create a custom tooltip
                          <Line
                            dataKey="organization"
                            stroke="black"
                            name="Organization"
                          />
                          <Line
                            type="monotone"
                            dataKey="result"
                            stroke="none"
                            name="Value"
                            isAnimationActive={false}
                            dot={<CustomizedDot />}
                          />
                          <ReferenceLine
                            y={formattedStatistic}
                            stroke="black"
                            strokeWidth={3}
                            strokeDasharray="9 9"
                          >
                            <Label
                              value={`${
                                analysis === "median"
                                  ? "Median"
                                  : "85th Percentile"
                              }: ${formattedStatistic} ${row.unit_name}`}
                              position="insideBottomRight"
                              stroke="black"
                            />
                          </ReferenceLine>
                          <XAxis
                            dataKey="collect_date"
                            type="number"
                            // minTickGap={25}
                            domain={["auto", "auto"]}
                            tickFormatter={(unixTime) =>
                              dateFormatter(unixTime, "MM-DD-YYYY")
                            }
                          />
                          <YAxis
                            label={{
                              value: `${row.param_abbrev} (${row.unit_name})`,
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </>
                  ) : (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      <strong>
                        **There is no daily data available for these filters.**
                        —
                      </strong>
                      please try again!
                    </Alert>
                  )}
                </Card>

                <Card style={{ margin: "12px" }}>
                  {barData?.length > 0 ? (
                    <>
                      <Typography variant="h3" align="center" mt={2}>
                        Annual Time Series
                      </Typography>
                      <ResponsiveContainer height={250} width={"100%"}>
                        <BarChart
                          // barGap={10}
                          height={250}
                          barSize={50}
                          margin={{
                            top: 25,
                            right: 75,
                            bottom: 25,
                            left: 75,
                          }}
                          data={barData}
                        >
                          <RechartsTooltip
                            content={<CustomAnnualTooltip flow={flow} />}
                          />
                          <ReferenceArea
                            y1={0}
                            fill={barData[0]?.bmk_color0 || "#EEEEEE"}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={barData[0]?.bmk_line0}
                            fill={barData[0]?.bmk_color1}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={barData[0]?.bmk_line1}
                            fill={barData[0]?.bmk_color2}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={barData[0]?.bmk_line2}
                            fill={barData[0]?.bmk_color3}
                            fillOpacity={1}
                          />
                          <ReferenceArea
                            y1={barData[0]?.bmk_line3}
                            fill={barData[0]?.bmk_color4}
                            fillOpacity={1}
                          />

                          <CartesianGrid strokeDasharray="1 6" stroke="white" />
                          <Legend />
                          <Bar
                            type="monotone"
                            dataKey={`result_${
                              analysis === "median" ? "median" : "pctile"
                            }_${flow}`}
                            name="Median"
                            fill="#8884d8"
                            isAnimationActive={false}
                            // barSize={35}
                            // maxBarSize={50}
                            // barGap={10}
                          />

                          <Bar
                            type="monotone"
                            dataKey={`result_${
                              analysis === "median" ? "median" : "pctile"
                            }_${flow}`}
                            name="85th percentile"
                            fill="#A5A5A5"
                            isAnimationActive={false}
                            // barSize={35}
                            // maxBarSize={50}
                            // barGap={10}
                          />

                          <ReferenceLine
                            y={formattedStatistic}
                            stroke="black"
                            strokeWidth={3}
                            strokeDasharray="9 9"
                          >
                            <Label
                              value={`${
                                analysis === "median"
                                  ? "Median"
                                  : "85th Percentile"
                              }: ${formattedStatistic} ${row.unit_name}`}
                              position="insideBottomRight"
                              stroke="black"
                            />
                          </ReferenceLine>

                          <XAxis dataKey="collect_year" />

                          <YAxis
                            label={{
                              value: `${row.param_abbrev} (${row.unit_name})`,
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  ) : (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      <strong>
                        **There is no annual data available for these filters.**
                        —
                      </strong>
                      please try again!
                    </Alert>
                  )}
                </Card>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  );
}

const DataViz = ({
  open = false,
  onClose,
  analyticsResults = [],
  benchmarkScaleColors,
  timeSeriesResults = {},
  filterValues,
  isTimeSeriesResultsLoading,
  isAnalyticsTableDataLoading,
  selectedMonitoringLocation,
}) => {
  const { location_name, locationid, location_type } =
    analyticsResults[0] || "";

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: "460px",
    timeSeries: "500px",
    chart: "250px",
  });

  const handleExpand = () => {
    let newState = { ...dataVizHeight };
    if (newState.viz === "460px" && newState.timeSeries === "500px") {
      newState.viz = "70vh";
      newState.timeSeries = "100%";
      newState.chart = 500;
    } else {
      newState.viz = "460px";
      newState.timeSeries = "500px";
      newState.chart = 250;
    }
    setDataVizHeight(newState);
  };

  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
    >
      {analyticsResults?.length > 0 &&
      selectedMonitoringLocation &&
      filterValues?.parameters?.value?.length > 0 ? (
        <Viz height={dataVizHeight.viz}>
          <CloseContainer>
            <ExpandButton
              handleExpand={handleExpand}
              expanded={dataVizHeight.viz !== "460px"}
            />
            <Tooltip title="Close" arrow>
              <IconButton
                size="small"
                onClick={onClose}
                style={{ marginLeft: "4px" }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </CloseContainer>

          <Panel overflowY="scroll" overflowX="hidden">
            {isAnalyticsTableDataLoading && <Loader />}
            <Paper>
              <TimeseriesContainer height={dataVizHeight.timeSeries}>
                <Box ml={4} pt={2} pb={2} display="flex">
                  <CircleMarker>
                    <Room />
                  </CircleMarker>
                  <RenderTitle
                    locationName={location_name}
                    locationId={locationid}
                    locationType={location_type}
                  />
                </Box>

                <TableContainer
                  style={{
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 600 }}>
                          Parameter
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          {filterValues.analysis.value === "median"
                            ? "Median"
                            : "85th Percentile"}
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Benchmarks
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Trend
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Count
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Benchmark Date Range
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Full Period of Record
                        </TableCell>
                        <TableCell style={{ fontWeight: 600 }} align="center">
                          Visualizations
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {analyticsResults
                        .sort((a, b) =>
                          a[filterValues.analysis.value] <
                          b[filterValues.analysis.value]
                            ? 1
                            : b[filterValues.analysis.value] <
                              a[filterValues.analysis.value]
                            ? -1
                            : 0
                        )
                        .map((row) => {
                          return (
                            <TimeSeriesGraphRow
                              row={row}
                              benchmarkScaleColors={benchmarkScaleColors}
                              data={timeSeriesResults}
                              isTimeSeriesResultsLoading={
                                isTimeSeriesResultsLoading
                              }
                              filterValues={filterValues}
                              key={row.param_abbrev}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TimeseriesContainer>
            </Paper>
          </Panel>
        </Viz>
      ) : !selectedMonitoringLocation ||
        !filterValues?.parameters?.value?.length > 0 ? (
        <Alert severity="info" style={{ marginBottom: "-18px" }}>
          <AlertTitle>Info</AlertTitle>
          <strong>
            Please select at least one parameter on the left legend and a
            monitoring location on the map above.
          </strong>
        </Alert>
      ) : (
        <Alert severity="error" style={{ marginBottom: "-18px" }}>
          <AlertTitle>Error</AlertTitle>
          <strong>**There is no data available with this query.** — </strong>
          please try again!
        </Alert>
      )}
    </OuterContainer>
  );
};

export default DataViz;
