import { useState, useCallback } from "react";
import axios from "axios";
import { useApp } from "../../../../AppProvider";

const useDynamicPeriodOfRecord = ({ startDate, endDate, refetchWqSummary }) => {
  const { doToast } = useApp();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const pollStatus = useCallback(
    async (cancelToken) => {
      for (let count = 0; count < 36; count++) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/dynamic-period-of-record/status`,
            { cancelToken }
          );
          if (data.status !== "running") {
            doToast("success", "Records were updated successfully.");
            refetchWqSummary();
            return;
          }
          await new Promise((resolve) => setTimeout(resolve, 5000));
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error("Polling error:", error);
            doToast("error", "Polling error occurred.");
          }
          return;
        }
      }
      console.error("Query taking longer than normal. Timeout invoked.");
      doToast("error", "Query taking longer than normal. Timeout invoked.");
      setIsSubmitLoading(false);
    },
    [doToast, refetchWqSummary]
  );

  const send = useCallback(async () => {
    const source = axios.CancelToken.source();
    setIsSubmitLoading(true);
    try {
      const { data: rebuildStatus } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/dynamic-period-of-record/rebuild`,
        { startDate, endDate },
        { cancelToken: source.token }
      );

      if (
        rebuildStatus.message !==
        "No update needed; dates match the current records."
      ) {
        await pollStatus(source.token);
      } else {
        doToast(
          "info",
          "Records are already reflecting the selected dates. No update needed."
        );
        setIsSubmitLoading(false);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        doToast("error", err?.message ?? "Something went wrong.");
      }
    } finally {
      setIsSubmitLoading(false);
    }
  }, [startDate, endDate, doToast, pollStatus]);

  const handleSubmit = useCallback(() => {
    send().catch((error) => console.error("An error occurred", error));
  }, [send]);

  return { handleSubmit, isSubmitLoading };
};

export default useDynamicPeriodOfRecord;
