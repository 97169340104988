import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Slider,
  Accordion,
} from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useDebounce from "../../../../hooks/useDebounce";

/**
 * Utility used to translate a Mapbox paint style
 * into an array of legend items
 * Currently only setup to support a basic fill color and
 * the 'match' flavor of Mapbox Expressions/data driven styling
 * Reference https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#match
 * @param {object} layer Mapbox layer representation
 * @returns
 */
const nutrientsLegend = {
  1: "Below benchmark",
  2: "Approaching benchmark",
  3: "Above benchmark",
  4: "Above secondary benchmark",
};

const getLegendOptions = (layer) => {
  /**
   * Get the proper object property and color associated with the layer
   * based on the layer type
   */
  const colorProperty = `${layer?.type}-color`;
  const color = layer?.paint?.[colorProperty];

  /**
   * If it is just a standard color rule (i.e. no data driven styling), just
   * grab the color,
   * Otherwise, if we are using Mapbox expressions/data-driven styling we need
   * to parse the paint property and convert it into an array of legend
   * items
   */
  if (!Array.isArray(color)) {
    return [{ color, text: layer.name }];
  }

  const colorsExpression = [...color];

  // grab the fallback value (i.e. what is used if a feature doesn't match any category)

  /**
   * Loop through the mapbox expression and pull out the color and the
   * category it is associated with
   * The expression that is being parsed is in a format like
   * [["Industrial"], "#1f78b4", ["Ag/Irrigation"],"#b2df8a"]
   * so even odd indexes in the array represent categories and even number
   * indexes represent the associated color
   * As a result we have to loop through the expression and merge
   * two items into a single one
   */

  let legendOptions;
  const fallbackValue = colorsExpression.splice(colorsExpression.length - 1, 1);

  if (colorsExpression[0] === "case") {
    // remove some unused parts of the expression
    colorsExpression.splice(0, 1);
    legendOptions = colorsExpression
      .map((value, index) => {
        if (index < colorsExpression.length - 1 && index % 2 === 0) {
          if ([1, 2, 3, 4].includes(value[0])) value = nutrientsLegend[value];
          return {
            color: colorsExpression[index + 1],
            text: Array.isArray(value)
              ? (index > 1 ? colorsExpression[index - 2][2] : 0) +
                (index >= colorsExpression.length - 3 ? "+" : " - " + value[2])
              : value,
          };
        }
        return null;
      })
      .filter((d) => d !== null);

    if (
      !layer?.paint["circle-color"]?.includes("No Values Available") &&
      layer.id === "locations-circle"
    ) {
      legendOptions.push({
        color: fallbackValue,
        text:
          layer.id === "locations-circle" ? "Multiple Filter Values" : "N/A",
      });
    }
  } else {
    // remove some unused parts of the expression
    colorsExpression.splice(0, 2);

    /**
     * Loop through the mapbox expression and pull out the color and the
     * category it is associated with
     * The expression that is being parsed is in a format like
     * [["Industrial"], "#1f78b4", ["Ag/Irrigation"],"#b2df8a"]
     * so even odd indexes in the array represent categories and even number
     * indexes represent the associated color
     * As a result we have to loop through the expression and merge
     * two items into a single one
     */
    legendOptions = colorsExpression
      .map((value, index) => {
        if (index < colorsExpression.length - 1 && index % 2 === 0) {
          // if (
          //   Array.isArray(value) &&
          //   [
          //     "Developed, Low Intensity",
          //     "Developed, Medium Intensity",
          //     "Developed, High Intensity",
          //   ].includes(value?.join(", "))
          // ) {
          //   return null;
          // }
          // if (value?.join(", ") === "Developed, Open Space")
          //   value = "NLCD (2019) - Developed/Impervious";
          return {
            color: colorsExpression[index + 1],
            text: Array.isArray(value) ? value?.join(", ") : value,
          };
        }
        return null;
      })
      .filter((d) => d !== null);
  }

  // Add the fallback value to the end of array
  // legendOptions.push({ color: fallbackValue, text: "N/A Value" });
  return legendOptions;
};

const LegendSymbol = ({ color }) => (
  <Box
    borderRadius="50%"
    height={12}
    width={12}
    style={{ minWidth: "12px", backgroundColor: `${color}` }}
  />
);

const LayerLegendIcon = ({ open }) =>
  open ? <ExpandMore /> : <ChevronRight />;

const LayerSlider = ({ item, onOpacityChange }) => {
  const [sliderValue, setSliderValue] = useState(
    item.paint["fill-opacity"] * 100
  );
  const debouncedSliderValue = useDebounce(sliderValue, 500);
  const hasMountedRef = useRef(false);

  const applyOpacityChange = useCallback(
    (newOpacity) => {
      const change = (id) => onOpacityChange({ id, opacity: newOpacity });

      change(item.id);
      if (item.lreProperties?.alsoControlOpacityFor) {
        change(item.lreProperties.alsoControlOpacityFor);
      }
    },
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }

    const newOpacity = parseInt(debouncedSliderValue, 10) / 100;
    if (!Number.isNaN(newOpacity)) {
      applyOpacityChange(newOpacity);
    }
  }, [debouncedSliderValue, applyOpacityChange]);

  if (
    !(
      item?.type === "fill" &&
      item.name !== "Search Circle Radius" &&
      (item?.paint["fill-opacity"] === 0 || item?.paint["fill-opacity"])
    )
  ) {
    return null;
  }

  return (
    <Box mb={-2}>
      <Typography id="fill-opacity" mt={1} mb={-1}>
        Fill Opacity
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        value={+sliderValue.toFixed(0)}
        onChange={(event, newValue) => setSliderValue(newValue)}
        aria-labelledby="continuous-slider"
      />
    </Box>
  );
};

const LayerLegend = ({ item, open, onOpacityChange }) => {
  if (!open) return null;
  const legendItems = getLegendOptions(item);
  return (
    <Box display="flex" flexDirection="column" gridRowGap={4} mb={2} mx={11}>
      {legendItems.map(({ color, text }) => (
        <Box key={text} display="flex" alignItems="center" gridColumnGap={8}>
          <LegendSymbol color={color} />
          <Typography color="textSecondary" variant="body2">
            {text}
          </Typography>
        </Box>
      ))}
      <LayerSlider item={item} onOpacityChange={onOpacityChange} />
    </Box>
  );
};

/**
 * TODOS
 * [] Add support for layers search
 */
const LayersControl = ({
  items,
  onLayerChange,
  onOpacityChange,
  defaultExpandedLayers = [
    "Monitoring Point Locations",
    "Cherry Creek Basin Boundary",
    "Wastewater Treatment Facilities",
  ],
}) => {
  const [expandedItems, setExpandedItems] = useState(defaultExpandedLayers);

  /**
   * Generate a unique list of items to display in the layer
   * controls list
   * This approach allows us to represent grouped layers with a single
   * item in the list while still controlling the visibility values for
   * all of the associated grouped layers
   */
  const uniqueItems = useMemo(() => {
    const uniqueItemIds = [
      ...new Set(
        items.map((item) => {
          return item?.lreProperties?.layerGroup || item.id;
        })
      ),
    ];
    return uniqueItemIds
      .reduce((acc, curr) => {
        const match = items.find((item) => {
          const id = item?.lreProperties?.layerGroup || item.id;
          return id === curr;
        });
        acc.push(match);
        return acc;
      }, [])
      ?.sort((a, b) => {
        return (a.legendOrder ?? 0) > (b.legendOrder ?? 0) ? -1 : 1;
      });
  }, [items]);

  const uniqueLegendGroups = [
    "Cherry Creek Basin",
    "Basemap",
    "Projects",
    "Water Quality",
    "Point Sources",
    "Non Point Sources",
    "Land Use",
    "Hydrology",
  ];

  /**
   * Handler that controls the visibility of each layer group
   */
  const handleVisibilityChange = (item) => {
    const itemId = item?.lreProperties?.layerGroup || item.id;
    onLayerChange({
      id: itemId,
      visible: item?.layout?.visibility === "none",
    });
  };

  /**
   * Handler used to control the expanded/collapsed state of the
   * legend for a layer
   */
  const handleExpandItem = (value) => {
    setExpandedItems((prevState) => {
      const newValues = [...prevState];
      const existingIndex = newValues.indexOf(value);
      if (existingIndex > -1) {
        newValues.splice(existingIndex, 1);
      } else {
        newValues.push(value);
      }
      return newValues;
    });
  };
  return (
    <Box style={{ width: "100%" }} display="flex" flexDirection="column">
      <List dense>
        {uniqueItems?.length === 0 && (
          <Box textAlign="center">
            <Typography variant="body1">No layers found</Typography>
          </Box>
        )}
        {uniqueLegendGroups.map((legendGroup) => {
          return (
            <Accordion
              key={legendGroup}
              defaultExpanded={["Cherry Creek Basin"].includes(legendGroup)}
            >
              <AccordionSummary
                style={{ display: "flex", flexDirection: "row-reverse" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{legendGroup}</Typography>
              </AccordionSummary>
              {uniqueItems
                .filter(
                  (uniqueItem) =>
                    uniqueItem.lreProperties?.legendGroup === legendGroup
                )
                .map((item) => {
                  const open = expandedItems.includes(item?.name);
                  const layerVisible = item?.layout?.visibility === "visible";
                  return (
                    <Box key={item?.name} id="layerCheck">
                      <ListItem
                        // style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleVisibilityChange(item);
                          (!layerVisible &&
                            !open &&
                            handleExpandItem(item?.name)) ||
                            (layerVisible &&
                              open &&
                              handleExpandItem(item?.name));
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={layerVisible}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": "test" }}
                        />
                        <ListItemText
                          primary={item?.name}
                          primaryTypographyProps={{
                            color: layerVisible
                              ? "textPrimary"
                              : "textSecondary",
                          }}
                        />
                        {!item?.lreProperties?.excludeLayerCollapse && (
                          <ListItemSecondaryAction
                            onClick={() => handleExpandItem(item?.name)}
                          >
                            <IconButton edge="end" aria-label="delete">
                              <LayerLegendIcon open={open} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                      {!item?.lreProperties?.excludeLayerCollapse && (
                        <LayerLegend
                          open={open}
                          item={item}
                          onOpacityChange={onOpacityChange}
                        />
                      )}
                    </Box>
                  );
                })}
            </Accordion>
          );
        })}
      </List>
    </Box>
  );
};

export default LayersControl;
